/** @format */

import React, { useContext, useState, useEffect } from 'react';
import { addDays, format } from 'date-fns';
import { CheckIcon, BellIcon, CrownIcon } from 'lucide-react';
import Button from 'blackbird/components/button/Button';
import Logo from 'blackbird/components/logo/Logo';
import { TrialContext, TrialProvider } from './TrialContext';
import { RequestActions } from 'javascripts/flux/actions/request';
import { PricingTestimonials } from 'blackbird/components/pricing/PricingTestimonials';
import { SignupLogos } from '../pages/onboarding/SignupLogos';
import TextInput from '../form/text-input/TextInput';
import classNames from 'classnames';
import logger from 'javascripts/helpers/logger';
import { TrialPlans } from './TrialPlans';

const TrialStart: React.FC = () => {
  const {
    createTrial,
    validateEmail,
    isCreatingTrial,
    isValidatingEmail,
    validateEmailError,
  } = useContext(TrialContext);
  const [trialDates, setTrialDates] = useState({
    startDate: new Date(),
    reminderDate: new Date(),
    endDate: new Date(),
  });
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  useEffect(() => {
    IntercomActions.enterContext.defer('signup');
    const start = new Date();
    const reminder = addDays(start, 4);
    const end = addDays(start, 7);
    setTrialDates({
      startDate: start,
      reminderDate: reminder,
      endDate: end,
    });
    return () => {
      IntercomActions.leaveContext.defer('signup');
    };
  }, []);

  const handleStartTrial = React.useCallback(async () => {
    if (isEmailValid) {
      const isValidEmail = await validateEmail(email);
      if (isValidEmail) {
        await createTrial({
          interval: 'month',
          currency: 'usd',
          email,
        });
      }
    }
  }, [createTrial, validateEmail, email, isEmailValid]);

  const isValidEmailFormat = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  useEffect(() => {
    setIsEmailValid(isValidEmailFormat(email));
  }, [email]);

  const Timeline: React.FC = () => (
    <div className="relative space-y-0">
      <div className="absolute top-0 bottom-0 w-0.5 bg-black left-[0.68rem]"></div>
      <div className="absolute top-full w-0.5 border-l-2 border-dashed border-black left-[0.68rem] h-4"></div>
      <div className="relative flex items-start pb-6 space-x-4">
        <div className="z-10 flex items-center justify-center w-6 !h-6 leading-none rounded-full bg-brand-pink flex-shrink-0">
          <CheckIcon strokeWidth={3} className="w-3 h-3 text-white" />
        </div>
        <div className="-mt-0.5">
          <p className="font-semibold">
            {`Today - Free trial for 7 days. Cancel anytime.`}
          </p>
          <p className="text-sm text-type-subdued">
            {`Start your free trial of our most powerful features.`}
          </p>
        </div>
      </div>
      <div className="relative flex items-start pb-6 space-x-4">
        <div className="z-10 flex items-center justify-center w-6 !h-6 leading-none rounded-full bg-black flex-shrink-0">
          <BellIcon strokeWidth={3} className="w-3 h-3 text-white" />
        </div>
        <div className="-mt-0.5">
          <p className="font-semibold">
            {format(trialDates.reminderDate, 'd MMMM')}
          </p>
          <p className="text-sm text-type-subdued">
            {`We'll remind you 3 days before your trial ends.`}
          </p>
        </div>
      </div>
      <div className="relative flex items-start space-x-4">
        <div className="z-10 flex items-center justify-center w-6 !h-6 leading-none rounded-full bg-black flex-shrink-0">
          <CrownIcon strokeWidth={3} className="w-3 h-3 text-white" />
        </div>
        <div className="-mt-0.5">
          <p className="font-semibold">
            {format(trialDates.endDate, 'd MMMM')}
          </p>
          <p className="text-sm text-type-subdued">
            {`Your subscription starts, unless you cancel during your trial.`}
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex justify-center min-h-full md:px-8">
      <div className="grid md:grid-cols-2 gap-8 max-w-[110ch]">
        {/* Left column (positioning & proof) */}
        <div className="items-center hidden md:flex">
          <div className="pb-10">
            <Logo mark={false} className="w-40 mb-6" />
            <h1 className="text-4xl space-x-1 !leading-[2.6rem] font-semibold">
              <span>{`Create client-ready storyboards 2x faster.`}</span>
              <span className="block text-brand-pink">{`Without the stress.`}</span>
            </h1>

            {/* Trial Explanation Timeline */}

            <div className="mt-8 mb-10">
              <Timeline />
            </div>
            <div className="max-w-lg pr-10">
              <PricingTestimonials />
            </div>
            <div className="pr-10">
              <div className="mt-8 mb-3 text-sm text-type-disabled">
                {`Trusted by creative teams worldwide:`}
              </div>
              <SignupLogos />
            </div>
          </div>
        </div>

        {/* Right Column (trial start form) */}
        <div className="flex items-center md:pl-10">
          <div className="w-full">
            <div className="flex items-center flex-grow px-6 pt-8 pb-10 bg-white sm:px-8 sm:border-t-4 sm:border-black sm:rounded-t-sm sm:shadow-md sm:rounded-b-xl">
              <div className="flex flex-col flex-grow space-y-8">
                <div className="">
                  <div className="flex justify-center mb-4 sm:hidden">
                    <Logo mark={false} className="w-48 " />
                  </div>
                  <div className="hidden mt-0 text-2xl font-semibold text-center sm:block">
                    {`Try Boords for free`}
                  </div>
                  <div className="flex justify-center">
                    <div className="max-w-sm mt-2 text-center text-type-subdued">
                      {`Free 7 day trial, cancel any time. We'll remind you before your trial ends.`}
                    </div>
                  </div>
                </div>

                <div className="px-4 py-3 sm:hidden bg-surface rounded-xl">
                  <PricingTestimonials />
                </div>
                {/* Plan selection */}
                <div className="hidden sm:block">
                  <TrialPlans />
                </div>
                <div className="block sm:hidden">
                  <Timeline />
                </div>

                <div className="flex flex-col pt-4 gap-4 sm:pt-0">
                  {validateEmailError && (
                    <div className="flex justify-center px-4 py-3 rounded-lg bg-surface">
                      {validateEmailError}
                    </div>
                  )}
                  <TextInput
                    inputSize="md"
                    name="email"
                    type="email"
                    placeholder="Your Work Email"
                    inputClassName="pl-4 pb-3"
                    fieldClassName="!rounded-full"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <Button
                    loading={isCreatingTrial || isValidatingEmail}
                    disabled={
                      isCreatingTrial || isValidatingEmail || !isEmailValid
                    }
                    type="pink"
                    rounded
                    size="lg"
                    onClick={handleStartTrial}
                  >
                    <span
                      className={classNames(!isEmailValid ? 'opacity-50' : '')}
                    >
                      {`Continue →`}
                    </span>
                  </Button>
                </div>

                <div className="space-y-4">
                  <div className="text-sm text-center space-x-1">
                    <span className="text-type-subdued">
                      {`By continuing you agree to our`}
                    </span>
                    <a
                      className="no-underline hover:underline"
                      href="https://boords.com/page/terms-of-service"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {`Terms`}
                    </a>
                    <span className="text-type-subdued">{`and`}</span>
                    <a
                      className="no-underline hover:underline"
                      href="https://boords.com/legal/privacy"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {`Privacy Policy`}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-center mt-8 text-sm opacity-0 sm:opacity-100 space-x-1 text-type-subdued">
              <p>{`Already have an account?`}</p>
              <a
                className="no-underline hover:underline text-type-primary"
                href={'/login'}
              >{`Sign in here`}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TrialStartWrapped: React.FC = () => {
  return (
    <TrialProvider>
      <TrialStart />
    </TrialProvider>
  );
};
