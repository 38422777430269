/** @format */
import React, { useContext } from 'react';
import { ProjectsContext } from './ProjectsContext';
import { EditableTitle } from '../common/EditableTitle';
import { ProjectActions } from './menus/ProjectActions';
import { TeamProjectMembers } from '../team/TeamProjectMembers';

export const ProjectContentsTitle: React.FC = () => {
  const { activeProject, updateProject, activeGroup } =
    useContext(ProjectsContext);

  if (!activeProject) return null;

  const titleClassName = `text-xl font-semibold`;

  return (
    <>
      <div className="flex items-center mb-5">
        {activeGroup?.actions.includes('projects.rename') ? (
          <EditableTitle
            value={activeProject.name}
            className={titleClassName}
            isEditable
            autoFocus={false}
            onChange={(newValue: string) => {
              updateProject(activeProject.id, {
                name: newValue,
              });
            }}
          />
        ) : (
          <div className={titleClassName}>{activeProject.name}</div>
        )}
        <div className="flex items-center flex-auto pt-1 ml-2 space-x-2">
          <ProjectActions />
          <TeamProjectMembers />
        </div>
      </div>
    </>
  );
};
