/** @prettier */
import * as React from 'react';
import {
  type Price,
  type LocalPlanData,
} from 'blackbird/helpers/contexts/BillingContext';
import { type FeatureName, Feature, FeatureInline } from '../features/Feature';
import { formatNumber } from 'javascripts/helpers/currencyHelper';
import { PricingContext } from './PricingContext';
import { PricingTableUserCost } from './PricingTablePuffinUserCost';

interface PricingTableFeaturesProps {
  plan: LocalPlanData;
  price?: Price;
  title?: React.ReactNode;
  useExtendedFeatures?: boolean;
}

interface PricingTableCreditCountProps {
  plan: LocalPlanData;
  iconColor?: string;
}

interface PricingTableUserCountProps {
  plan: LocalPlanData;
  hideSingleUser?: boolean;
}

interface PricingTablePuffinUserCountProps {
  plan: LocalPlanData;
  price: Price;
  hideSingleUser?: boolean;
}

export const PricingTableUserCount: React.FC<PricingTableUserCountProps> = ({
  plan,
  hideSingleUser,
}) =>
  plan.maxUsers === 1 ? (
    hideSingleUser ? null : (
      <Feature label={`Single User`} />
    )
  ) : (
    <Feature
      label={
        <>
          <span>{`Up to`}</span>
          <span className="font-semibold">{` ${plan.maxUsers} `}</span>
          <span>{`Users`}</span>
        </>
      }
    />
  );

export const PricingTableCreditCount: React.FC<
  PricingTableCreditCountProps
> = ({ plan }) => {
  const { billingInterval } = React.useContext(PricingContext);

  return plan.maxImageCredits === 10000 ? (
    <Feature
      tooltip={`Unlimited AI image credits so you can experiment and test as much as you like.`}
      label={
        <>
          <span className="font-semibold">{`Unlimited `}</span>
          <span>{`AI Image Credits`}</span>
        </>
      }
    />
  ) : (
    <Feature
      tooltip={`Credits are only used when you insert images, so you can experiment and test as much as you like. Credits are replenished at the end of each billing cycle`}
      label={
        <>
          <span className="font-semibold">{`${formatNumber(
            plan.maxImageCredits * (billingInterval === 'year' ? 12 : 1),
          )} `}</span>
          <span>{`AI Image Credits`}</span>
        </>
      }
    />
  );
};

export const PricingTableStoryboardCount: React.FC<
  PricingTableCreditCountProps
> = ({ plan, iconColor }) => (
  <Feature
    iconColor={iconColor}
    tooltip={
      <span className="text-sm">
        {plan.maxStoryboards === 10000 ? (
          <>{`Create an unlimited number of storyboards for total creative freedom.`}</>
        ) : (
          <>{`Create up to ${plan.maxStoryboards} storyboards with unlimited images and text.`}</>
        )}
      </span>
    }
    label={
      plan.maxStoryboards === 10000 ? (
        <>
          <span className="mr-1 font-semibold">{`Unlimited`}</span>
          <span>{`Storyboards`}</span>
        </>
      ) : (
        <>
          <span>{`Up to`}</span>
          <span className="font-semibold">{` ${plan.maxStoryboards} `}</span>
          <span>{`Storyboards`}</span>
        </>
      )
    }
  />
);

export const PricingTablePuffinUserCount: React.FC<
  PricingTablePuffinUserCountProps
> = ({ plan, price }) => (
  <Feature
    tooltip={`Invite teammates to create, edit, and collaborate on storyboards and projects.`}
    label={
      <span className="inline-flex items-baseline space-x-1 whitespace-nowrap">
        <span className="underline decoration-dotted underline-offset-2 decoration-border-mid space-x-1">
          {plan.maxUsers === 10000 ? (
            <span className="font-semibold">{`Unlimited`}</span>
          ) : (
            <>
              <span>{`Up To`}</span>
              <span className="font-semibold">{plan.maxUsers}</span>
            </>
          )}
          <span>{`Teammates`}</span>
        </span>
        <span className="text-xs text-type-subdued">
          <>{`(+`}</>
          <PricingTableUserCost price={price} />
          <>{`/mo each)`}</>
        </span>
      </span>
    }
  />
);

export const PricingTableFeatures: React.FC<PricingTableFeaturesProps> = ({
  plan,
  price,
  title,
  useExtendedFeatures,
}): React.ReactElement => {
  return (
    <>
      <div className="pb-4 -ml-1 space-y-2">
        <div className="pb-1 font-semibold">
          {title ? (
            <span>{title}</span>
          ) : plan.slug === 'free' ? (
            <span>{`Essential storyboarding`}</span>
          ) : plan.slug === 'individual' ? (
            <span>{`Ideal starter plan`}</span>
          ) : plan.slug === 'group' ? (
            <span>{`For up to 5 users`}</span>
          ) : plan.slug === 'agency' ? (
            <span>{`For up to 10 users`}</span>
          ) : (
            <span>{`For up to 30 users`}</span>
          )}
        </div>

        {['workflow', 'standard'].includes(plan.slug) && price && (
          <PricingTablePuffinUserCount plan={plan} price={price} />
        )}

        {plan.slug !== 'workflow' && (
          <PricingTableStoryboardCount plan={plan} />
        )}

        {plan.slug === 'free' ? (
          <FeatureInline name="limited-ai-credits" />
        ) : (
          <PricingTableCreditCount plan={plan} />
        )}

        {useExtendedFeatures && plan.extendedFeatures ? (
          <>
            {plan.extendedFeatures.map((f: FeatureName) => (
              <FeatureInline name={f} key={`feature-${f}`} />
            ))}
          </>
        ) : (
          <>
            {plan.additionalFeatures &&
              plan.additionalFeatures.map((f: FeatureName) => (
                <FeatureInline
                  name={
                    f === 'team-comments' &&
                    BoordsConfig.Rollout.Teamonlycomments
                      ? 'team-only-comments'
                      : f
                  }
                  key={`feature-${f}`}
                  tooltipPlacement="top-start"
                />
              ))}
          </>
        )}
      </div>
    </>
  );
};
