/** @format */

import React from 'react';
import EmptyState from 'blackbird/images/icons/empty-state-v2.svg';
import classNames from 'classnames';

interface ProjectsEmptyProps {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  className?: string;
}
export const ProjectsEmpty: React.FC<ProjectsEmptyProps> = ({
  title,
  subtitle,
  children,
  className,
}) => {
  const emptyStyle = { width: '333px', height: '221px' };

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className={classNames('text-center', className)}>
        <div className="flex justify-center mt-2 mb-8">
          <EmptyState
            className="relative scale-75 opacity-30"
            style={emptyStyle}
          />
        </div>

        <div className="text-xl">{title}</div>
        <div className="mt-3 mb-6 text-type-subdued">{subtitle}</div>
        {children}
      </div>
    </div>
  );
};
