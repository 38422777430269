/** @format */
/* eslint-disable react/jsx-handler-names */
import * as React from 'react';
import ReactDOM from 'react-dom';
import CommentIcon from 'blackbird/images/icons/comment-3.svg';
import { useOnMount } from 'javascripts/helpers/useOnMount';
import { useTranslation } from 'react-i18next';
import FrameCommentsContainer from './containers/FrameCommentsContainer';
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import { EmptyState } from 'blackbird/components/feedback/empty-state/EmptyState';
import { NewCommentForm } from 'blackbird/components/comments/editor/NewCommentForm';
import type { FrameCommentsContainerProps } from './containers/FrameCommentsContainer';
import type { DetailedFrame } from 'javascripts/types/frame';
import type { IStoryboardInStore } from 'javascripts/types/storyboard';

import '../../flux/actions/comment';
import '../../flux/stores/comment';
import '../../flux/stores/project_team';
import '../../flux/actions/project_team';
import '../../flux/stores/user';

interface Props {
  activeFrame: DetailedFrame;
  canManage: boolean;
  storyboard: Pick<IStoryboardInStore, 'id' | 'slug'>;
  portalRef?: HTMLDivElement | null;
}

export const FrameComments: React.FC<Props> = (
  props: Props & FrameCommentsContainerProps,
) => {
  const { t } = useTranslation();
  useOnMount(props.fetch);

  const sections = props.comment.commentSections;
  const currentSection =
    sections && sections.find((s) => s.frameId === props.activeFrame.id);

  let commentList;

  if (!props.comment.fetched || !props.projectTeam) {
    // we sometimes need team
    commentList = <LoadingIndicator pad />;
  } else if (!currentSection) {
    commentList = (
      <EmptyState icon={<CommentIcon />}>
        {t('comments.no_frame_comments')}
      </EmptyState>
    );
  } else {
    const commentCount = currentSection.count;
    commentList = (
      <PanelbarCommentsSection
        storyboard={props.storyboard}
        headerText={t('counters.comments', { count: commentCount })}
        comments={currentSection.comments}
        frameId={currentSection.frameId}
        canManage={props.canManage}
        active={false}
        team={props.projectTeam}
        user={props.user}
        handleCommentUpdate={props.handleCommentUpdate}
        handleCommentDelete={props.handleCommentDelete}
        handleCommentSave={props.handleCommentSave}
      />
    );
  }

  return (
    <div className="flex flex-col flex-grow">
      {commentList}

      {props.portalRef &&
        ReactDOM.createPortal(
          <NewCommentForm
            context="panelbar"
            allowNameField={true}
            frameId={props.activeFrame.id}
            storyboardSlug={props.storyboard.slug}
          />,
          props.portalRef,
        )}
    </div>
  );
};

const Connected = FrameCommentsContainer(FrameComments);
export default Connected;
