/** @format */

import React, { useState, useContext, useEffect } from 'react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import Dialog from '../dialog/Dialog';
import { teamMonthlyUserCost, TeamContext, teamBaseCost } from './TeamContext';
import { CheckIcon } from 'lucide-react';
import classNames from 'classnames';
import Button from '../button/Button';
import cdnUrl from 'javascripts/helpers/cdn-url.js';
import { hideWorkflowButtonLocalState } from 'javascripts/helpers/local-state';
import {
  WorkflowEndTrialButton,
  WorkflowStartTrialButton,
} from './WorkflowTrialButtons';
import { differenceInDays } from 'date-fns';

interface Feature {
  title: string;
  description: string;
  image: string;
}

const features: Feature[] = [
  {
    title: 'Nested project organization',
    description: `The new nested folder system helps you effortlessly organize projects and storyboards using the intuitive drag-and-drop folder tree.

    Easily organize by client, project status, or any custom structure that fits your workflow.`,
    image: '/assets/workflow-features/nesting-large.jpg',
  },
  {
    title: 'Team-only comments',
    description: `Team-only comments are hidden from guests, visible only to your project team.

      Share thoughts, give feedback, and plan revisions without worrying about clients seeing internal communication.
      `,
    image: '/assets/workflow-features/team-only-zoom.jpg',
  },
  {
    title: 'All-new project view',
    description: `See entire projects at a glance with the all-new project view that shows storyboards and sub-projects, all in one place.

      Keep your team aligned by adding project descriptions, resource links, and creative direction.`,
    image: '/assets/workflow-features/project-view-sort.jpg',
  },
  {
    title: 'Shareable project links',
    description: `Share multiple storyboards in a single, secure link - no more juggling separate URLs.

      Add password protection for extra security, and optionally include sub-projects and project descriptions to give clients the full picture.`,
    image: '/assets/workflow-features/shareable-project-link.jpg',
  },
  {
    title: 'Improved team management',
    description: `Invite new members and assign roles effortlessly with the new team management interface.

      Manage access directly from the project view for improved visibility, and onboard team members in seconds using invite links.`,
    image: '/assets/workflow-features/team-management-detail.jpg',
  },
  {
    title: 'Enhanced navigation, security & more',
    description: `Quickly navigate from project to storyboard and back with new breadcrumb trails.

      Boost your team's security with two-factor authentication, and share your work professionally on a custom, branded subdomain.`,
    image: '/assets/workflow-features/breadcrumb.jpg',
  },
];

declare const BoordsConfig: {
  TeamRole?: string;
  IsAnnual?: boolean;
  Freeloader?: boolean;
  V3TrialEndsAt?: number;
};

interface FlyoverWorkflowPreviewProps {
  isOpenedByAdmin?: boolean;
}

const FlyoverWorkflowPreview: React.FC<FlyoverWorkflowPreviewProps> = ({
  isOpenedByAdmin,
}) => {
  const { activeGroup } = useContext(TeamContext);
  const [openItem, setOpenItem] = useState<string>(features[0].title);

  useEffect(() => {
    features.forEach((feature) => {
      const img = new Image();
      img.src = cdnUrl(feature.image);
    });
  }, []);

  const getDaysRemaining = () => {
    if (!BoordsConfig.V3TrialEndsAt) {
      return null;
    }
    const now = new Date();
    const trialEndDate = new Date(BoordsConfig.V3TrialEndsAt * 1000); // Convert Unix timestamp to milliseconds
    const daysRemaining = differenceInDays(trialEndDate, now);
    return daysRemaining < 0 ? null : Math.max(0, daysRemaining);
  };

  const daysRemaining = getDaysRemaining();

  const handleClose = () => {
    if (window.location.pathname === '/workflow') {
      window.history.pushState(undefined, 'Boords', '/');
    }
    FlyoverActions.close.defer();
  };

  const toggleItem = (title: string) => {
    setOpenItem((prevOpenItem) => (prevOpenItem === title ? '' : title));
  };

  const isAdmin =
    (activeGroup && activeGroup.actions.includes('team.billing')) ||
    (BoordsConfig.TeamRole && BoordsConfig.TeamRole === 'admin') ||
    BoordsConfig.Freeloader ||
    isOpenedByAdmin;

  useEffect(() => {
    Track.event.defer('workflow_flyover_view', {
      isAdmin: Boolean(isAdmin),
      posthogCapture: true,
    });
  }, [isAdmin]);

  const UpgradeCta: React.FC = () => (
    <div className="flex items-start pl-6 pr-4 pt-3 pb-3.5 rounded-lg bg-premium-100 border-premium-200 border">
      {isAdmin ? (
        <>
          <div className="flex-auto pb-0.5">
            {!BoordsConfig.V3TrialEndsAt ? (
              <>
                <div className="text-base font-semibold text-premium-800">{`Try Workflow for free, opt-out any time.`}</div>
                <div className="text-sm space-x-1 mt-0.5 text-premium-700 opacity-60">{`Your plan/billing won't change unless you choose to upgrade.`}</div>
              </>
            ) : (
              <>
                <div className="text-base font-semibold text-premium-800">{`Complete your Workflow upgrade`}</div>

                <div className="text-sm space-x-1 mt-0.5 text-premium-700 opacity-60">
                  <span>{`Workflow is`}</span>
                  <span className="font-semibold">{teamBaseCost}</span>
                  <span>{`plus`}</span>
                  <span className="font-semibold">{teamMonthlyUserCost}</span>
                  <span>{`for each additional user.`}</span>
                </div>

                {daysRemaining !== null && (
                  <div className="text-sm space-x-1 mt-0.5 ">
                    <span className="text-premium-700 opacity-60">{`Don't want to upgrade?`}</span>
                    <WorkflowEndTrialButton />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex-shrink-0 text-center">
            {!BoordsConfig.V3TrialEndsAt ? (
              <WorkflowStartTrialButton />
            ) : (
              <>
                <Button
                  link={`/checkout/workflow/${
                    BoordsConfig.IsAnnual ? 'yearly' : 'monthly'
                  }`}
                  type="premium"
                  rounded
                >
                  {`Go to Checkout`}
                </Button>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="pb-0.5 flex-auto">
          <div className="text-sm text-premium-900">{`Please contact the team owner to request an upgrade.`}</div>
          <div className="text-xs text-premium-900 opacity-50 mt-0.5">{`Questions? Talk to the team via the chat icon.`}</div>
        </div>
      )}
    </div>
  );

  return (
    <Dialog
      size="md"
      isOpen
      keepOpen
      isBlur
      title={
        <div className="-mt-4 fullsize:-mt-8">
          <div>
            <span className="bg-premium-100 !text-premium-600 uppercase font-semibold rounded-sm px-1.5 py-0.5 !text-xxs tracking-wider ">{`Introducing Workflow`}</span>
          </div>
          <div className="mt-2 space-x-1 !text-2xl">
            <span>{`Your Pre-Production Workflow,`}</span>
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-premium-700 to-premium-900">{`Redefined`}</span>
          </div>
        </div>
      }
      subtitle={
        <div className="w-full max-w-lg">{`Workflow is a powerful new Boords plan that gives fast-moving creative teams a game-changing advantage in pre-production.`}</div>
      }
      onCloseBtnClick={handleClose}
      onEscapeOrOutsideClick={handleClose}
      hideActions
    >
      <div className="pb-6">
        <div className="mb-8">
          <UpgradeCta />
        </div>
        <div>
          {features.map((feature, index) => {
            const isFeatureOpen = openItem === feature.title;
            return (
              <div
                key={index}
                className={classNames(
                  'mb-4 border rounded-lg border-premium-100 bg-premium-50',
                  !isFeatureOpen ? '' : '',
                )}
              >
                <div
                  className="flex justify-between w-full px-4 pt-3 pb-2 text-base font-semibold text-left cursor-pointer focus:outline-none "
                  onClick={() => {
                    if (!isFeatureOpen) {
                      toggleItem(feature.title);
                    }
                  }}
                >
                  <div className="flex items-center pb-1">
                    <span className="flex-shrink-0 w-8">
                      <div className="flex items-center justify-center w-5 h-5 rounded-full bg-premium-100">
                        <CheckIcon
                          strokeWidth={3}
                          className="w-3.5 h-3.5 text-premium-500"
                        />
                      </div>
                    </span>
                    <span className="pb-1">{feature.title}</span>
                  </div>
                  <ChevronUpIcon
                    className={`${
                      isFeatureOpen
                        ? 'transition-transform transform rotate-180'
                        : ''
                    } w-5 h-5`}
                  />
                </div>
                {isFeatureOpen && (
                  <div className="px-4 pb-4 ml-8 ">
                    <div className="grid grid-cols-2">
                      <div className="pr-8 text-base">
                        <Markdown
                          text={feature.description}
                          className="text-prose"
                        />
                      </div>
                      <div className="w-full rounded-lg aspect-square">
                        <img
                          src={cdnUrl(feature.image)}
                          alt={feature.title}
                          className="border rounded-lg border-premium-100 aspect-square"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="pl-6 mt-6">
          {daysRemaining !== null ? (
            <></>
          ) : (
            <span
              className="text-xs opacity-50 cursor-pointer text-premium-800 hover:underline underline-offset-2"
              onClick={() => {
                hideWorkflowButtonLocalState.setValue(true);
                FlyoverActions.close.defer();
                Track.event.defer('workflow_flyover_dismiss', {
                  isAdmin: Boolean(isAdmin),
                  posthogCapture: true,
                });
              }}
            >
              {`Don't remind me again`}
            </span>
          )}
        </div>
      </div>
    </Dialog>
  );
};

(window as any).FlyoverWorkflowPreview = FlyoverWorkflowPreview;
