/** @prettier */

import React, { createContext, useState, useMemo } from 'react';

import Dialog, { ConfirmationDialogProps, DialogProps } from './Dialog';

const DialogContext = createContext<{
  dialogState?: DialogProps,
  setDialogState: React.Dispatch<React.SetStateAction<DialogProps | undefined>>

}>({
  dialogState: undefined,
  setDialogState: () => { }
});
const DialogContextProvider: React.FC = ({
  children,
}) => {
  const [dialogState, setDialogState] = useState<DialogProps>()
  const dialogContextValue = useMemo(() => ({ dialogState, setDialogState }), [dialogState])

  return (
    <DialogContext.Provider value={dialogContextValue}>
      {children}
      {dialogState && (
        <Dialog
          {...dialogState}
        />
      )}

    </DialogContext.Provider>
  );
};

export default DialogContextProvider;
export { DialogContext };
