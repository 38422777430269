/** @prettier */
import * as React from 'react';
import { FrameFieldIconPicker } from './FrameFieldIconPicker';
import type { FrameField } from '../../../types/storyboard';
import Toggle from 'blackbird/components/toggle/Toggle';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import { IconButton } from 'blackbird/components/common/IconButton';
import DeleteIcon from 'blackbird/images/icons/delete-20.svg';
import { isFunction, omit } from 'underscore';
import Icon from 'blackbird/components/icon/Icon';
import DragHandleIcon from 'blackbird/images/icons/drag-handle.svg';
import classNames from 'classnames';
import {
  sortableOnChangeHandler,
  useSortable,
} from 'blackbird/helpers/hooks/useSortable';

interface Props extends FrameField {
  name: string;
  placeholder: string;
  fieldVersion: unknown;
  onChange: (name: string, newState: FrameField) => void;
  draggable?: boolean;
  /** If passed, will allow deleting the item instead of disabling */
  onDelete?: (name: string) => void;
  index: number;
  onReorder: sortableOnChangeHandler;
  onCommitReorder: () => void;
}

type FrameFieldState = FrameField & { changePayload?: FrameField };

const getEmptyState = (props: Props): FrameFieldState => ({
  label: props.label ?? '',
  isEnabled: props.isEnabled,
  icon: props.icon,
  id: props.id,
});

export const FrameFieldItem = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const [fieldState, setFieldState] = React.useState<FrameFieldState>(
      getEmptyState(props),
    );

    const { name, onChange } = props;

    React.useEffect(() => {
      setFieldState(getEmptyState(props));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fieldVersion]);

    React.useEffect(() => {
      if (fieldState.changePayload) onChange(name, fieldState.changePayload);
    }, [fieldState.changePayload, onChange, name]);

    const handleBlur = React.useCallback(() => {
      const label = fieldState.label ?? '';

      setFieldState((oldState) => {
        const newState = { ...oldState, label: label.trim() };
        newState.changePayload = omit(newState, 'changePayload');
        return newState;
      });
    }, [fieldState.label]);

    /** Directly updates the field and updates state */
    const updateField = React.useCallback(
      (name: keyof FrameField, value, save = false) =>
        setFieldState((oldState) => {
          const newState = { ...oldState, [name]: value };
          if (save) newState.changePayload = omit(newState, 'changePayload');
          return newState;
        }),
      [],
    );

    const dragRef = React.useRef<HTMLDivElement>(null);
    const previewRef = React.useRef<HTMLDivElement>(null);
    const { styles, handlerId, isDragging } = useSortable({
      type: 'frameFieldItem',
      dragRef,
      previewRef,
      onChange: props.onReorder,
      onEnd: props.onCommitReorder,
      currentIndex: props.index,
      enabled: props.draggable,
    });

    return (
      <div
        className="flex items-center gap-4 flex-auto"
        ref={previewRef}
        style={styles}
        data-handler-id={handlerId}
      >
        {props.draggable && (
          <div
            className={classNames(
              '-ml-1.5 -mt-0.5',
              isDragging ? 'cursor-grabbing' : 'cursor-grab',
            )}
            ref={dragRef}
          >
            <Icon
              icon={<DragHandleIcon />}
              className={'h-5 w-auto pointer-events-none'}
              color="subdued"
            />
          </div>
        )}
        <div className="flex flex-grow">
          <FrameFieldIconPicker
            value={fieldState.icon}
            onChange={(value) => updateField('icon', value, true)}
          />
          <TextInput
            inputSize="md"
            type="text"
            autoComplete="off"
            name={name}
            placeholder={props.placeholder}
            value={fieldState.label}
            onChange={(e) => updateField('label', e.currentTarget.value)}
            onBlur={handleBlur}
            ref={ref}
          />
        </div>

        {isFunction(props.onDelete) ? (
          <IconButton
            icon={<DeleteIcon />}
            onClick={() => props.onDelete!(props.name)}
            aria-label="Delete"
            className="w-8"
            iconClassName="h-5 w-auto"
            hasTooltip={false}
          />
        ) : (
          <Toggle
            value={fieldState.isEnabled}
            onChange={(value) => updateField('isEnabled', value, true)}
          />
        )}
      </div>
    );
  },
);

FrameFieldItem.displayName = 'FrameFieldItem';
