/** @format */
import { useTranslation } from 'react-i18next';
import { TextArea } from 'blackbird/components/form/text-input/TextInput';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Button from '../button/Button';
import Select from 'blackbird/components/form/select/Select';
import NewStoryboardProjectsList from 'javascripts/components/new_project/NewStoryboardProjectsList';
import { ModalDivider } from '../dialog/Dialog';
import { StoryboardActions } from 'javascripts/flux/actions/storyboard';
import { RequestActions } from 'javascripts/flux/actions/request';
import {
  BoordsAiContext,
  type AiPreset,
  type AiLanguage,
} from 'blackbird/helpers/contexts/BoordsAiContext';
import i18n from 'i18next';
import NewStoryboardAspectRatio from 'javascripts/components/new_project/NewStoryboardAspectRatio';
import { TourHintable } from 'javascripts/components/tours/TourHintable';
import { type availableTourNames } from 'javascripts/flux/stores/tours';
import { ToursActions } from 'javascripts/flux/actions/tours';
import { type allTourSteps } from 'javascripts/tours/tourData';
import { WizardContext } from '../wizard/WizardContext';
import { GeneratorStyles } from '../generator/GeneratorStyles';
import { SecondaryLink } from '../common/SecondaryLink';
import classNames from 'classnames';
import { AiExamples } from './AiExamples';

const storyboardErrorHandler = RequestErrorHandler('storyboard');
const maxLength = 1000;

interface AiPromptProps {
  storyboard_id?: number;
  project_id?: number;
}

type AiPromptRequiredProps = AiPromptProps &
  ({ storyboard_id: number } | { project_id: number }) & {
    onSubmit?: () => void;
    actionChildren?: React.ReactNode;
    showDivider?: boolean;
    simplified?: boolean;
  };

interface HelpDocLinkProps {
  cta?: string;
}

export const AiHelpDocLink: React.FC<HelpDocLinkProps> = ({
  cta = i18n.t('ai.settings.cta'),
}) => {
  const { t } = useTranslation();
  return (
    <a
      className="underline hover:no-underline text-type-subdued decoration-border-mid"
      rel="noopener noreferrer"
      target="_blank"
      href={t('ai.helpDoc')}
    >
      {cta}
    </a>
  );
};

export const AiPrompt: React.FC<AiPromptRequiredProps> = ({
  project_id,
  storyboard_id,
  actionChildren,
  simplified,
  showDivider = true,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const {
    preset,
    language,
    presets,
    categories,
    category,
    setCategory,
    presetOptions,
    languages,
    setPreset,
    setLanguage,
    style,
  } = useContext(BoordsAiContext);

  const { handleComplete } = useContext(WizardContext);

  const [storyboardId, setStoryboardId] = useState<number>();
  const [prompt, setPrompt] = useState<string>('');
  const [overlong, setOverlong] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [showLanguage, setShowLanguage] = useState<boolean>(false);

  const StartAi = useCallback(async () => {
    if (storyboardId && preset) {
      Track.event.defer('ai_generate', {
        category: 'AI',
        value: preset.slug,
      });
      const response = await fetch(
        `/api/storyboards/${storyboardId}/ai_scripts`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-API-KEY': BoordsConfig.AuthenticationToken,
          },
          body: JSON.stringify({
            data: {
              attributes: {
                prompt: `${
                  preset.category === 'blank' ? '' : preset.title
                } ${prompt}`,
                style: style.value,
                language: language.value,
              },
            },
          }),
        },
      );
      if (response.ok) {
        const json = await response.json();
        if (project_id) {
          RequestActions.redirect.defer(json.data[0].attributes.edit_url);
        }
      } else {
        setSubmitted(false);
        storyboardErrorHandler()(new Error(response.statusText));
      }
    }
  }, [project_id, storyboardId, prompt, preset, language]);

  useEffect(() => {
    if (storyboard_id) {
      setStoryboardId(storyboard_id);
    }
  }, [storyboard_id]);

  // Kick off AI generation when storyboard id is
  // set after creation (i.e. not on a pre-existing storyboard)
  useEffect(() => {
    if (project_id && storyboardId) {
      StartAi();
    }
  }, [storyboardId, project_id, StartAi]);

  useEffect(() => {
    Track.event.defer('ai_open', { category: 'AI' });
  }, []);

  const stepIsActive = (stepName: allTourSteps) =>
    ToursStore.state.currentSteps.length > 0 &&
    ToursStore.state.currentSteps[0].name === stepName;

  const tourIsActive = (tourName: availableTourNames) =>
    ToursStore.state.currentTours.length > 0 &&
    ToursStore.state.currentTours[0].name === tourName;

  const Generate = async () => {
    setSubmitted(true);

    if (
      tourIsActive('wizardNewStoryboard') &&
      stepIsActive('wizardNewStoryboardInput')
    ) {
      ToursActions.advanceTour('wizardNewStoryboard');
      handleComplete('create-storyboard');
    }
    if (onSubmit) {
      onSubmit();
    }
    if (project_id && !storyboardId) {
      StoryboardActions.create({
        documentName: 'Untitled',
        callbackOnly: true,
        projectId: project_id,
        callback: async (success, response) => {
          if (success) {
            // This calls StartAi from a useEffect hook
            setStoryboardId(response.data.id);
          } else {
            setSubmitted(false);
            storyboardErrorHandler({
              messageKey: 'storyboard.errors.create',
            });
          }
        },
      });
    } else {
      StartAi();
    }
  };

  useEffect(() => {
    if (simplified) {
      // By default, select "Free form" when generating
      // a new storyboard for simplified view
      const selectedCategory = categories.find((c) => c.value === 'blank');
      if (selectedCategory) {
        setCategory(selectedCategory);
      }
    }
  }, [simplified, categories]);

  return (
    <div>
      <div className="">
        {project_id && !simplified && (
          <div className="my-4">
            <NewStoryboardProjectsList size="md" projectID={project_id} />
          </div>
        )}
        {preset && presets && presetOptions && (
          <>
            {!simplified && (
              <div className="my-4 grid grid-cols-2 gap-4">
                {categories && (
                  <Select
                    disableInput
                    size="md"
                    label={t('ai.storyboardTypeLabel')}
                    selectContainerClassName="w-full"
                    options={categories.filter(
                      (c) => !simplified || c.value !== 'all',
                    )}
                    value={category.value}
                    onChange={(value) => {
                      const selectedCategory = categories.find(
                        (c) => c.value === value,
                      );
                      if (selectedCategory) {
                        setCategory(selectedCategory);

                        Track.event.defer('ai_use_category', {
                          category: 'AI',
                          value: value,
                        });
                      }
                    }}
                  />
                )}
                {presetOptions.length > 1 && (
                  <Select
                    disableInput
                    size="md"
                    label={t('ai.presetLabel')}
                    selectContainerClassName="w-full"
                    options={presetOptions}
                    value={preset.slug}
                    onChange={(value: string) => {
                      setPreset(
                        presets.find(
                          (preset: AiPreset) => preset.slug === value,
                        ) as AiPreset,
                      );

                      Track.event.defer('ai_use_preset', {
                        category: 'AI',
                        value: value,
                      });
                    }}
                  />
                )}
              </div>
            )}

            {!simplified && <GeneratorStyles isNewStoryboardStyles />}

            {/* Example buttons for simplified onboarding version */}
            {simplified && (
              <div className="mb-4">
                <AiExamples setPrompt={setPrompt} handleGenerate={Generate} />
                <div className="mt-8 text-center text-type-subdued">{`or describe your storyboard:`}</div>
              </div>
            )}

            <div
              className={classNames(
                'flex-col flex md:flex-row items-center',
                simplified && 'space-x-3',
              )}
            >
              {/* Text Input */}
              <div className="flex-auto w-full px-3 mt-4 md:px-0">
                <TourHintable
                  overlayPosition="right"
                  step={`wizardNewStoryboardInput`}
                  hideNext
                >
                  <TextArea
                    autoFocus={true}
                    label={simplified ? undefined : `${preset.placeholder}:`}
                    labelClasses="text-sm font-semibold"
                    placeholder={
                      simplified
                        ? `Describe your storyboard`
                        : `e.g. ${preset.example}`
                    }
                    onChange={(inputValue) => {
                      if (inputValue.length > maxLength) {
                        setOverlong(true);
                      } else {
                        setOverlong(false);
                      }
                      setPrompt(inputValue);
                    }}
                    plainText
                    minRows={simplified ? 1 : 4}
                    immediate
                  />
                </TourHintable>
                {!simplified && (
                  <div className="flex justify-end mt-1 text-sm text-type-subdued">
                    <span className={`${overlong ? 'text-form-error' : ''}`}>
                      {prompt.length}
                    </span>
                    <>{`/${maxLength}`}</>
                  </div>
                )}
              </div>
              {simplified && (
                <div className="mt-4">
                  <Button
                    type="solid"
                    size="lg"
                    disabled={prompt.length < 5 || overlong || submitted}
                    onClick={() => Generate()}
                  >
                    {!submitted
                      ? t('ai.submitSimplified')
                      : t('ai.submitProcessing')}
                  </Button>
                </div>
              )}
            </div>

            {/* Set language and frame size */}
            {!simplified && (
              <div className={classNames('-mt-6', showLanguage && 'opacity-0')}>
                <SecondaryLink
                  onClick={() => setShowLanguage(true)}
                >{`Set language & frame size`}</SecondaryLink>
              </div>
            )}

            {language && !simplified && showLanguage && (
              <div className="mt-2">
                <div className="grid grid-cols-2 gap-4">
                  <Select
                    disableInput
                    size="md"
                    selectContainerClassName="w-full"
                    label={t('ai.languageLabel')}
                    options={languages}
                    value={language.value}
                    onChange={(value: string) => {
                      setLanguage(
                        languages.find(
                          (lang: AiLanguage) => lang.value === value,
                        ) as AiLanguage,
                      );

                      Track.event.defer('ai_use_language', {
                        category: 'AI',
                        value: value,
                      });
                    }}
                  />

                  <NewStoryboardAspectRatio />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {!simplified && (
        <div className="space-y-8">
          <div className="mt-8">{showDivider && <ModalDivider />}</div>
          <div
            className={`flex  ${
              simplified ? 'justify-start -translate-y-6' : 'justify-end'
            }`}
          >
            {!simplified && (
              <div className="flex items-center flex-auto flex-start space-x-2">
                {actionChildren && <>{actionChildren}</>}
                <AiHelpDocLink />
              </div>
            )}
            <Button
              type="fancy"
              size="md"
              disabled={prompt.length < 5 || overlong || submitted}
              onClick={() => Generate()}
            >
              {!submitted
                ? simplified
                  ? t('ai.submitSimplified')
                  : t('ai.submit')
                : t('ai.submitProcessing')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
