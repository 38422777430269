/** @format */

import classNames from 'classnames';
import logger from 'javascripts/helpers/logger';
import React from 'react';
import { TeamContext } from './TeamContext';

interface TeamUpgradePillProps {
  linkToPricing?: boolean;
  showPlanName?: boolean;
}

export const TeamUpgradePill: React.FC<TeamUpgradePillProps> = ({
  linkToPricing = true,
  showPlanName = true,
}) => {
  const { teamData, activeGroup } = React.useContext(TeamContext);

  const handleClick = React.useCallback(() => {
    const isTeamAdmin = teamData?.active_and_pending_members.some(
      (m) => m.user_id === BoordsConfig.Uid && m.role === 'admin',
    );
    if (
      (activeGroup && activeGroup.actions.includes('team.billing')) ||
      (BoordsConfig.TeamRole && BoordsConfig.TeamRole === 'admin') ||
      isTeamAdmin
    ) {
      // Show direct upgrade (puffin)
      if (BoordsConfig.IsPuffinTrial || BoordsConfig.PlanSlug === 'standard') {
        FlyoverActions.open.defer({ type: 'inlinePricing' });
      } else {
        FlyoverActions.open.defer({ type: 'workflowPreview' });
      }
    } else {
      FlyoverActions.open.defer({ type: 'workflowPreview' });
    }
  }, [activeGroup, teamData]);

  return (
    <>
      <span
        className={classNames(
          'bg-premium-100 !text-premium-600 uppercase font-semibold rounded-sm px-1.5 py-0.5 text-xxs tracking-wider hover:bg-premium-200',
          linkToPricing ? 'cursor-pointer' : '',
        )}
        onClick={linkToPricing ? handleClick : undefined}
      >
        {showPlanName ? <>{`Upgrade to Workflow`}</> : <>{`Upgrade`}</>}
      </span>
    </>
  );
};
