/** @format */

import React from 'react';
import classNames from 'classnames';
import type { WizardItem, WizardItemType } from './types';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { WizardTokenCount } from './WizardTokenCount';
import { wizardActionMap } from './wizardActionMap';
import { XMarkIcon } from '@heroicons/react/20/solid';
import Tooltip from '../feedback/tooltip/Tooltip';
import { WizardContext } from './WizardContext';

interface WizardItemProps {
  item: WizardItem;
  expanded?: boolean;
}

export const WizardListItem: React.FC<WizardItemProps> = ({
  item,
  expanded,
}) => {
  const { handleSkip } = React.useContext(WizardContext);

  return (
    <div
      className={classNames(
        'flex py-3 border rounded-md group relative cursor-pointer ',
        item.isComplete ? 'pr-2 pl-3 ' : 'pr-2 pl-4 ',
        expanded || item.isComplete
          ? 'bg-decorative-blue/10 border-decorative-blue'
          : 'bg-white border-border ',
        expanded ? 'items-end ' : 'items-center',
      )}
      onClick={wizardActionMap(item.id as WizardItemType)}
    >
      {item.isComplete && (
        <CheckCircleIcon className="flex-shrink-0 w-6 mr-1.5 text-brand-blue" />
      )}
      <div className={classNames('flex-auto', expanded && 'mr-3')}>
        <div
          className={classNames(
            'font-semibold text-sm ',
            item.isComplete || !expanded ? 'text-type-subdued' : '',
          )}
        >
          {item.title}
        </div>
        {expanded && <div className="mt-1 text-xs">{item.description}</div>}
      </div>

      <div className="flex-shrink-0">
        {expanded && (
          <Tooltip
            title={`Skip task`}
            placement="top"
            zIndexClassName="z-[10001]"
          >
            <XMarkIcon
              className="absolute w-5 opacity-0 cursor-pointer top-1 right-1 text-type-disabled hover:text-type-primary group-hover:opacity-100 "
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSkip(item.id);
              }}
            />
          </Tooltip>
        )}
        {/* <WizardTokenCount */}
        {/*   count={item.credits} */}
        {/*   isComplete={item.isComplete} */}
        {/*   isExpanded={expanded} */}
        {/* /> */}
      </div>
    </div>
  );
};
