/** @prettier */

import React, { FC, ReactNode } from 'react';
import type { FeedbackKind, ToastSize } from '../types';
import { feedbackBackgrounds, feedbackIcons } from '../constants';
import classNames from 'classnames';
import Icon from '../../icon/Icon';

export interface ToastProps {
  className?: string;
  kind?: FeedbackKind | null;
  size?: ToastSize;
  title?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  message: string;
  hideIcon?: boolean;
}

export const width: { [key in ToastSize]: string } = {
  sm: 'w-80',
  lg: 'w-96',
  full: 'w-full',
  auto: '',
};

const Toast: FC<ToastProps> = (props) => {
  const {
    className,
    kind = null,
    title,
    hideIcon = false,
    message,
    size = 'sm',
    onClick,
  } = props;

  return (
    <div
      className={classNames(
        'flex-nowrap py-4 px-6 items-center rounded-lg',
        kind ? feedbackBackgrounds[kind] : 'bg-action-primary text-white',
        !hideIcon && 'inline-flex',
        width[size],
        className,
      )}
    >
      {kind && !hideIcon && (
        <Icon
          className="w-6 mr-3.5 shrink-0"
          icon={feedbackIcons[kind]}
          color="black"
        />
      )}

      <div
        onClick={onClick}
        className={classNames(
          'text-sm',
          onClick && 'cursor-pointer',
          size === 'auto' && 'pr-2',
        )}
      >
        {Boolean(title) && <div className="flex font-bold">{title}</div>}
        {message}
        {props.children}
      </div>
    </div>
  );
};

export default Toast;
