/** @prettier */
import { useEffect, useContext, useState, useCallback, useRef } from 'react';
import { DialogContext } from './DialogContext';
import type {
  ConfirmationDialogProps,
  InformationalDialogProps,
} from './Dialog';
import type { RequireAtLeastOne } from '../../helpers/types';

type ConfirmHookProps = Omit<ConfirmationDialogProps, 'isOpen'>;
type InformationalHookProps = Omit<InformationalDialogProps, 'isOpen'>;

const useConfirmDialog = (props?: RequireAtLeastOne<ConfirmHookProps>) => {
  const propsRef = useRef<ConfirmHookProps>(props ?? {});
  const { onConfirm, onCancel } = propsRef.current;
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const { setDialogState } = useContext(DialogContext);

  const onConfirmAction = useCallback(() => {
    hideDialog();
    setIsConfirmed(true);
    onConfirm && onConfirm();
  }, [onConfirm]);

  const onCancelAction = useCallback(() => {
    hideDialog();
    setIsCancelled(true);
    onCancel && onCancel();
  }, [onCancel]);

  const coreHandlers = {
    onConfirm: onConfirmAction,
    onCancel: onCancelAction,
  };

  function showDialog() {
    setDialogState({ ...props, ...coreHandlers, isOpen: true });
  }
  function hideDialog() {
    setDialogState({ ...coreHandlers, isOpen: false });
  }

  useEffect(() => {
    setDialogState({ ...props });
  }, [propsRef.current]);

  useEffect(() => {
    setDialogState(coreHandlers);
  }, []);

  return {
    hideDialog,
    showDialog,
    isCancelled,
    isConfirmed,
  };
};

const useInformationalDialog = (
  props?: Omit<InformationalHookProps, 'isInformational'>,
) => {
  const propsRef = useRef<InformationalHookProps>({
    ...props,
    isInformational: true,
  });
  const { onConfirm } = propsRef.current;
  const onConfirmAction = useCallback(() => {
    hideDialog();
    setIsConfirmed(true);
    onConfirm && onConfirm();
  }, []);
  const initialState = {
    isInformational: true,
    onConfirm: onConfirmAction,
  };
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { setDialogState } = useContext(DialogContext);
  const showDialog = () => {
    setDialogState({ ...props, ...initialState, isOpen: true });
  };
  const hideDialog = () => {
    setDialogState({ ...props, ...initialState, isOpen: false });
  };

  useEffect(() => {
    setDialogState({ ...props });
  }, [propsRef.current]);

  useEffect(() => {
    setDialogState(initialState);
  }, []);

  return {
    hideDialog,
    showDialog,
    isConfirmed,
  };
};

export { useConfirmDialog, useInformationalDialog };
