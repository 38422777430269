/** @prettier */
import React, { useRef, useState, useEffect } from 'react';
import Button from 'blackbird/components/button/Button';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import Checkbox from 'blackbird/components/form/checkbox/Checkbox';
import { type OnboardingProps, OnboardingType } from './Onboarding';
import { Trans, useTranslation } from 'react-i18next';
import { type SubmitHandler, useForm } from 'react-hook-form';
import logger from 'javascripts/helpers/logger';
import { RequestActions } from 'javascripts/flux/actions/request';

import Logo from 'blackbird/components/logo/Logo';
import { SignupLogoTest } from './signup/SignupTest';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import LogoGoogle from 'blackbird/images/logo/google-vector.svg';
import { PricingTestimonials } from 'blackbird/components/pricing/PricingTestimonials';
import { SignupLogos } from './SignupLogos';
import { TrialStartWrapped } from 'blackbird/components/trial/TrialStart';
import classNames from 'classnames';

interface SignUpInput {
  user: {
    name: string;
    email: string;
    password: string;
    terms_of_service: string;
    is_marketing_opt_in?: string;
    token?: string;
  };
  authenticity_token: string;
  inviteToken?: string;
}
const SignupScreen: React.FC<OnboardingProps> = (props) => {
  const {
    register,
    formState,
    handleSubmit: handleValidation,
    reset,
  } = useForm<SignUpInput>({
    defaultValues: {
      user: {
        email: props.invitedEmail ?? '',
      },
    },
  });
  const errors = formState.errors?.user;
  const formRef = useRef<HTMLFormElement | null>(null);
  const [isBusy, setIsBusy] = useState(false);
  const [loginUrl, setLoginUrl] = useState(`/login`);
  const { t } = useTranslation();
  const [notification, setNotification] = useState(props.notification);
  const toggleToEmailScreen = () => {
    props.onToggleScreen && props.onToggleScreen(OnboardingType.SIGN_UP_EMAIL);
  };

  const handleSubmit: SubmitHandler<SignUpInput> = async (data) => {
    const name = data.user.name;
    const email = data.user.email;
    const password = data.user.password;
    if (props.onSignUp) {
      props.onSignUp({ email, password, name });
    } else if (!props.authenticityToken || !props.formAction) {
      throw new Error(
        'An authenticity token and formAction prop need to be supplied',
      );
    }
    setIsBusy(true);
    try {
      const response = await fetch(props.formAction!, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();

      if (response.ok) {
        location.href = jsonResponse.next_url;
      } else {
        return setNotification({
          kind: 'warning',
          message: jsonResponse.error,
        });
      }
    } catch (err) {
      logger.error('Failed to sign up', err);
    } finally {
      setIsBusy(false);
    }
  };
  useEffect(() => {
    if (formState.touchedFields.user?.email) {
      setNotification(undefined);
    }
  }, [formState]);

  useEffect(() => {
    if (!notification) return;
    if (notification.kind === 'error' || notification.kind === 'warning') {
      RequestActions.error.defer(notification.message);
    } else {
      RequestActions.success.defer(notification.message);
    }
  }, [notification]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const nextUrl = urlSearchParams.get('next_url');
    if (nextUrl) {
      setLoginUrl(`/login?next_url=${nextUrl}`);
    }
  }, []);

  const fromPaid =
    new URLSearchParams(window.location.search).get('fpr') === 'aura';
  const directToEmail =
    new URLSearchParams(window.location.search).get('auth') === 'email';
  const hasEmailValue = Boolean(
    new URLSearchParams(window.location.search).get('email'),
  );

  useEffect(() => {
    if (directToEmail) {
      toggleToEmailScreen();
    }
  }, [directToEmail, toggleToEmailScreen]);

  const showCCForm =
    !props.inviteToken &&
    String(BoordsConfig.CheckoutSignup) === 'true' &&
    !fromPaid;

  return showCCForm ? (
    <TrialStartWrapped />
  ) : (
    <div className="flex justify-center min-h-full md:px-8 ">
      <div className="grid md:grid-cols-2 gap-8 max-w-[110ch]">
        {/* Left column (positioning & proof) */}

        <div className="items-center hidden md:flex">
          <div className="pb-10">
            <Logo mark={false} className="w-40 mb-6" />
            <h1 className="text-4xl space-x-1 !leading-[2.6rem] font-semibold">
              <span>{`Create client-ready storyboards 2x faster.`}</span>
              <span className="block text-brand-pink">{`Without the stress.`}</span>
            </h1>
            <div className="max-w-md mt-20">
              <PricingTestimonials />
            </div>
            <div className="pr-10">
              <div className="mt-8 mb-3 text-sm text-type-disabled">
                {`Trusted by creative teams worldwide:`}
              </div>
              <SignupLogos />
            </div>
          </div>
        </div>
        {/* Right Column (signup form) */}
        <div className="flex items-center md:pl-10">
          <div className="w-full">
            <form
              ref={formRef}
              onSubmit={handleValidation(handleSubmit)}
              className="flex items-center flex-grow px-6 py-10 bg-white sm:px-8 sm:border-t-4 sm:border-black sm:rounded-t-sm sm:shadow-md sm:rounded-b-xl"
              method="post"
              action={props.formAction}
            >
              <input name="utf8" type="hidden" value="✓" />
              {props.authenticityToken && (
                <input
                  type="hidden"
                  {...register('authenticity_token')}
                  value={props.authenticityToken}
                />
              )}
              <fieldset className="flex flex-col flex-grow space-y-8">
                <div className="">
                  <div className="flex justify-center sm:hidden">
                    <Logo mark={false} className="w-48 mb-6" />
                  </div>
                  <div className="justify-center hidden sm:flex">
                    <Logo mark={true} className="w-12 h-12" />
                  </div>
                  {props.inviteToken ? (
                    <>
                      <div className="mt-4 text-xl font-semibold text-center">
                        <>{`Create your account`}</>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="hidden mt-4 text-xl font-semibold text-center sm:block">
                        <>{`Try Boords Free`}</>
                      </div>
                      <div className="mt-2 text-center text-type-subdued">
                        {`Try free for 7 days, no credit card required.`}
                      </div>

                      <div className="px-4 py-3 mt-8 sm:hidden bg-surface rounded-xl">
                        <PricingTestimonials />
                      </div>
                    </>
                  )}
                </div>
                {props.inviteToken && (
                  <input
                    type="hidden"
                    {...register('user.token')}
                    id="user_token"
                    value={props.inviteToken}
                  />
                )}
                {props.type === OnboardingType.SIGN_UP_EMAIL ? (
                  <div className="flex flex-col gap-6">
                    <div className="space-y-2">
                      <TextInput
                        {...register('user.name', {
                          required: 'Name is required.',
                        })}
                        placeholder={`Enter your name`}
                        label={t('signup.name')}
                        autoComplete="name"
                        className="flex-grow-0"
                        error={errors?.name?.message}
                      />

                      <TextInput
                        {...register('user.email', {
                          required: 'Email is required.',
                        })}
                        placeholder={`Your work email`}
                        label={t('signup.email')}
                        type="email"
                        className={classNames(
                          'flex-grow-0',
                          directToEmail && hasEmailValue && 'hidden',
                        )}
                        error={errors?.email?.message}
                      />

                      <TextInput
                        {...register('user.password', {
                          required: 'Password is required.',
                          minLength: {
                            value: 6,
                            message:
                              'Password is too short (minimum is 6 characters)',
                          },
                        })}
                        placeholder={`Choose a password`}
                        label={t('signup.password')}
                        type="password"
                        className="flex-grow-0"
                        autoComplete="new-password"
                        error={errors?.password?.message}
                      />

                      <div className="flex items-center justify-between pt-2">
                        <Checkbox
                          {...register('user.terms_of_service', {
                            validate: (v) =>
                              Boolean(v) || 'Please accept terms of service.',
                          })}
                          label={
                            <span className="inline-flex pb-1 -ml-1 text-sm text-type-subdued">
                              <Trans
                                t={t}
                                i18nKey="signup.terms"
                                // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                                components={{
                                  terms: (
                                    <a
                                      className="no-underline hover:underline !text-type-primary ml-1"
                                      href="https://boords.com/page/terms-of-service"
                                    >
                                      terms and conditions
                                    </a>
                                  ),
                                }}
                              />
                            </span>
                          }
                          controlled={false}
                          htmlValue="1"
                          error={errors?.terms_of_service?.message}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-4">
                      <Button
                        loading={formState.isSubmitting || isBusy}
                        disabled={formState.isSubmitting || isBusy}
                        rounded
                        htmlType="submit"
                        size="lg"
                      >
                        {t('signup.button')}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col gap-4">
                    <Button
                      size="lg"
                      type="outline"
                      rounded
                      link={props.googleSigninPath}
                    >
                      <div className="relative w-full">
                        <div className="absolute -left-1 top-[0.2rem]">
                          <LogoGoogle
                            width="1.2rem"
                            height="1.2rem"
                            alt="Google Logo"
                          />
                        </div>
                        <div className="w-full pl-2 text-center">{`Continue with Google`}</div>
                      </div>
                    </Button>
                    <Button
                      rounded
                      size="lg"
                      type="secondary"
                      onClick={toggleToEmailScreen}
                    >
                      <div className="relative w-full">
                        <EnvelopeIcon className="w-5 absolute -left-1 top-[0.2rem]" />
                        <div>{`Continue with email`}</div>
                      </div>
                    </Button>
                  </div>
                )}

                {props.type !== OnboardingType.SIGN_UP_EMAIL && (
                  <div className="space-y-4">
                    <div className="text-sm text-center space-x-1">
                      <span className="text-type-subdued ">{`By signing up you agree to our`}</span>
                      <a
                        className="no-underline hover:underline"
                        href="https://boords.com/page/terms-of-service"
                        target="_blank"
                        rel="noreferrer noopener"
                      >{`Terms`}</a>
                      <span className="text-type-subdued ">{`and`}</span>
                      <a
                        className="no-underline hover:underline"
                        href="https://boords.com/legal/privacy"
                        target="_blank"
                        rel="noreferrer noopener"
                      >{`Privacy Policy`}</a>
                    </div>
                  </div>
                )}

                <div className="sm:hidden">
                  <div className="mt-6 mb-4 text-sm text-center text-type-disabled">
                    {`Trusted by creative teams worldwide:`}
                  </div>
                  <SignupLogos />
                </div>
              </fieldset>
            </form>

            <div className="items-center justify-center hidden mt-8 text-sm sm:flex space-x-1 text-type-subdued">
              <p>{`Already have an account?`}</p>
              <a
                className="no-underline hover:underline text-type-primary"
                href={loginUrl}
              >{`Sign in here`}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignupScreen;
