/** @prettier */
import { Listbox } from '@headlessui/react';
import { Placement } from '@popperjs/core';
import useCustomPopper from 'blackbird/helpers/hooks/useCustomPopper';
import classNames from 'classnames';
import { getFirstCharacter } from 'javascripts/helpers/get-first-character';
import { useStoreAction } from 'javascripts/helpers/useStoreAction';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { type UserStore } from '../../../javascripts/flux/stores/user';
import { useStore } from '../../../javascripts/helpers/useStore';
import { type UserResponse } from '../../../javascripts/types/user';
import { slugToType } from '../../helpers/misc';
import Avatar from '../avatar/Avatar';
import { type AvatarSize } from '../avatar/types';
import Badge from '../badge/Badge';
import { type BadgeColor } from '../badge/types';
import { PuffinPlanName } from '../team/TeamContext';
import { TeamPlanBadge } from '../team/TeamPlanBadge';
import { TeamTrialBlock } from '../team/TeamTrialBlock';

interface UserDropdownContainerProps {
  size?: AvatarSize;
  placement?: Placement;
}

interface UserDropdownProps {
  size: AvatarSize;
  placement: Placement;
  name: string;
  email: string;
  avatarURL: string;
  plan: string;
  isFree: boolean;
  isProfessionalFree: boolean;
  isStoryboardLimited: boolean;
  teamCount: number;
  status: string;
  adminTeamIds: any[];
  logoutUrl?: string;
  hasFullCollaboration: boolean;
  openArchive?: () => void;
}
interface PlanBadgeProps {
  isFree?: boolean;
  openBillingOnClick?: boolean;
  planName: string;
  status: string;
}

const PlanBadge: React.FC<PlanBadgeProps> = (props) => {
  let tagText = slugToType(props.planName);
  let tagStyles = ['capitalize'];
  let tagLabel = '';
  let tagColor: BadgeColor = 'blue';
  let billingLink = '/billing';

  if (props.planName === 'Professional Trial' && props.status === 'canceled') {
    tagText = 'Trial Expired';
  } else if (props.planName === 'Professional Free') {
    tagText = 'Free';
  } else if (props.status === 'trialing') {
    billingLink = '/pricing';
  } else if (props.isFree && tagText !== 'free') {
    tagLabel = `${tagText} expired`;
    tagText = 'Free';
  } else if (props.status === 'canceled') {
    tagLabel = `${tagText} canceling`;
  } else if (tagText.toLowerCase().indexOf('professional') > -1) {
    tagColor = 'yellowToPink';
  }

  if (props.openBillingOnClick) {
    tagStyles = [...tagStyles, 'cursor-pointer'];
  }
  const tagStyle = tagStyles.join(' ');

  const handleClick = () => {
    if (props.openBillingOnClick) {
      window.location.href = billingLink;
    }
  };

  return (
    <div onClick={handleClick}>
      {props.planName === 'Trialing' ? (
        <TeamTrialBlock />
      ) : ['Standard', 'Workflow'].includes(props.planName) ? (
        <TeamPlanBadge planName={props.planName as PuffinPlanName} />
      ) : (
        <>
          <Badge label={tagText} color={tagColor} className={tagStyle} />
          <div className="sr-only">{tagLabel}</div>
        </>
      )}
    </div>
  );
};
const ListOption = React.forwardRef<
  HTMLAnchorElement,
  React.HTMLProps<HTMLAnchorElement>
>((props, ref) => {
  const { children, onSelect, className, ...rest } = props;
  const refValue = ref ?? React.useRef<HTMLAnchorElement>();
  const refProps = refValue
    ? {
        ref: refValue,
      }
    : {};
  return (
    <Listbox.Option as={React.Fragment} value={onSelect ?? refValue}>
      {({ active }) => {
        return (
          <a
            className={classNames(className, 'py-2 focus:outline-none px-6', {
              'bg-surface-light': active,
            })}
            {...rest}
            {...(refProps as any)}
          >
            {children}
          </a>
        );
      }}
    </Listbox.Option>
  );
});
ListOption.displayName = 'ListOption';

interface ListDividerProps {
  className?: string;
}
const ListDivider: React.FC<ListDividerProps> = ({ className }) => (
  <div className={classNames('border-t m-3 border-t-border')} />
);

export const UserDropdown: React.VFC<UserDropdownProps> = (props) => {
  const {
    name,
    email,
    hasFullCollaboration,
    isProfessionalFree,
    adminTeamIds,
    teamCount,
    status,
    isFree,
    size,
    placement,
    plan,
    logoutUrl = '/logout',
    avatarURL,
  } = props;

  const posthog = usePostHog();
  const [showTeamManagementLinks, setShowTeamManagementLinks] =
    React.useState<boolean>(true);
  const [parentRef, setParentRef] = React.useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(
    null,
  );

  React.useEffect(() => {
    if (isProfessionalFree && adminTeamIds.length === 0 && teamCount > 1) {
      setShowTeamManagementLinks(false);
    } else if (
      (isProfessionalFree && teamCount === 1) ||
      hasFullCollaboration ||
      adminTeamIds.length > 0
    ) {
      setShowTeamManagementLinks(true);
    } else {
      setShowTeamManagementLinks(false);
    }
  }, [hasFullCollaboration, isProfessionalFree, teamCount, adminTeamIds]);

  const { t } = useTranslation();
  const teamLink = React.useCallback(
    (suffix) => {
      if (
        (!hasFullCollaboration || isProfessionalFree) &&
        adminTeamIds !== undefined &&
        adminTeamIds.length > 0
      ) {
        return `/settings/team/${adminTeamIds[0]}/${suffix}`;
      } else {
        return `/settings/team/${suffix}`;
      }
    },
    [hasFullCollaboration, adminTeamIds, isProfessionalFree],
  );
  const manageLink = teamLink('manage');
  const domainLink = teamLink('domain');
  const usageLink = teamLink('usage');

  const handleClick = () => {
    props.openArchive && props.openArchive();
  };
  const { attributes, styles } = useCustomPopper(parentRef, popperElement, {
    placement: placement,
    distance: 10,
    maxHeight: 800,
  });
  const handleChange = (
    value: React.MutableRefObject<HTMLAnchorElement | null> | (() => void),
  ) => {
    if (!value) return;
    if (typeof value === 'function') return value();
    if ((window?.event as MouseEvent)?.ctrlKey) {
      value.current?.href && window.open(value.current?.href, '_blank');
      return;
    }
    value.current?.click();
  };
  return (
    <Listbox value={null} onChange={handleChange}>
      <Listbox.Button
        ref={(ref) => setParentRef(ref)}
        className="flex rounded-full cursor-pointer group focus:outline-none"
      >
        <Avatar
          size={size}
          className="border-2"
          image={avatarURL}
          initial={getFirstCharacter(name)}
        />
      </Listbox.Button>

      {ReactDOM.createPortal(
        <Listbox.Options
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="z-40 flex flex-col w-56 py-4 pb-3 overflow-y-auto text-sm bg-white rounded-lg shadow-lg focus:outline-none"
        >
          <div className="flex flex-col px-6 space-y-3">
            <span className="font-bold truncate text-type-primary">
              {email}
            </span>
            <div className="pb-2 -ml-1">
              <PlanBadge
                status={status}
                isFree={isFree}
                planName={plan}
                openBillingOnClick
              />
            </div>
          </div>

          <ListDivider />
          {(isFree || BoordsConfig.IsPuffinTrial) && (
            <ListOption
              className="cursor-pointer"
              onClick={() => {
                FlyoverActions.open.defer({
                  type: 'inlinePricing',
                });
              }}
            >
              {t('settings.pricing')}
            </ListOption>
          )}
          <ListOption href="/">{t('settings.dashboard')}</ListOption>
          <ListDivider />

          {showTeamManagementLinks && (
            <>
              <ListOption href={manageLink}>
                {t('settings.managePeople')}
              </ListOption>
              <ListDivider />
            </>
          )}

          {!isFree && (
            <ListOption href="/billing">{t('settings.billing')}</ListOption>
          )}

          <ListOption href="/settings/account/profile">
            {t('settings.profile')}
          </ListOption>
          <ListDivider />

          <ListOption
            target="_blank"
            rel="noopener noreferrer"
            href="//help.boords.com"
          >
            {t('settings.help')}
          </ListOption>
          <ListOption
            target="_blank"
            rel="noopener noreferrer"
            href="//help.boords.com/demo-videos"
          >
            {t('settings.videoTutorials')}
          </ListOption>
          <ListOption
            target="_blank"
            rel="noopener noreferrer"
            href="//community.boords.com/"
          >
            {t('settings.community')}
          </ListOption>

          <ListDivider />

          {!BoordsConfig.HasV3 && (
            <>
              <ListOption className="cursor-pointer" onSelect={handleClick}>
                {t('settings.archive')}
              </ListOption>
              <ListDivider />
            </>
          )}

          <ListOption
            onClick={() => {
              posthog.reset();
            }}
            href={logoutUrl}
          >
            {t('settings.logout')}
          </ListOption>
        </Listbox.Options>,
        document.querySelector('body')!,
      )}
    </Listbox>
  );
};

export const UserDropdownContainer: React.VFC<UserDropdownContainerProps> = ({
  size = 'lg',
  placement = 'bottom-end',
}) => {
  const user = useStore<UserResponse | undefined, UserStore>(
    'user',
    (state) => state.user,
  );
  const adminTeamIds = user?.admin_team_ids ?? [];
  const hasFullCollaboration = user?.has_full_collaboration ?? false;
  const status = user?.status ?? '';

  const handleOpenArchive = useStoreAction('FlyoverActions', 'open', {
    type: 'archivedItems',
  });
  if (!user) {
    return null;
  }
  return (
    <UserDropdown
      placement={placement}
      size={size}
      teamCount={user.teams.length}
      name={user.name}
      isFree={user.is_free || user.is_professional_free}
      isProfessionalFree={user.is_professional_free}
      isStoryboardLimited={user.is_storyboard_limited}
      avatarURL={user.avatar_url}
      email={user.email}
      hasFullCollaboration={hasFullCollaboration}
      adminTeamIds={adminTeamIds}
      plan={user.plan}
      status={status}
      openArchive={handleOpenArchive}
    />
  );
};
