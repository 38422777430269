/** @prettier */

import React, { FC, useEffect, useState } from 'react';
import { Option } from '../types';
import classNames from 'classnames';

export type ListItemSelectHandler = (option: Option | string) => void;
interface ListItemProps {
  option: Option | string;
  highlight?: boolean;
  scrollInView?: boolean;
  onClick: ListItemSelectHandler;
  [x: string]: any;
}

const ListItem: FC<ListItemProps> = (props) => {
  const {
    option,
    onClick,
    highlight,
    scrollInView = false,
    ...restProps
  } = props;
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref && highlight && scrollInView) {
      ref.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
  }, [ref, highlight, scrollInView]);

  const optionContent = () => {
    if (typeof option === 'object') {
      const { label, subLabel } = option as Option;

      return (
        <>
          {label}

          {Boolean(subLabel) && (
            <div className="text-type-subdued text-xs">{subLabel}</div>
          )}
        </>
      );
    }
    return null;
  };

  const stringContent = () => {
    if (typeof option === 'string') {
      return option as string;
    }

    return null;
  };
  const handleClick = (e) => {
    e.stopPropagation();
    onClick?.(option);
  };
  //Has to stop propagation in this handler instead of onClick
  // This is the caveat that comes with using Headless UI's Popover
  const handleMouseDown = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  return (
    <div
      ref={setRef}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      className={classNames(
        'flex w-full py-3 px-4 flex-nowrap flex-col cursor-pointer rounded',
        { 'hover:bg-surface-light': highlight === undefined },
        { 'bg-surface-light': highlight },
      )}
      {...restProps}
    >
      {optionContent()}

      {stringContent()}
    </div>
  );
};

export default ListItem;
