/** @prettier */

import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import type { ButtonSize, ButtonType } from './types';
import classNames from 'classnames';
import Icon from '../icon/Icon';
import type { IconColor } from '../icon/types';
import Spinner from '../../images/icons/spinner.svg';

export interface ButtonProps {
  className?: string;
  disabled?: boolean;
  icon?: React.ReactElement;
  iconLocation?: 'left' | 'right';
  link?: string;
  linkTarget?: string;
  loading?: boolean;
  rounded?: boolean;
  shadow?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: ButtonSize;
  type?: ButtonType;
  customPadding?: boolean;
  customBorder?: boolean;
  iconClassName?: string;
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  ref?:
    | ((instance: HTMLButtonElement | null) => void)
    | React.MutableRefObject<HTMLButtonElement | null>
    | null;
  /** Shows the OS's confirmation dialog before navigating or triggering `onClick` */
  confirm?: string;
  accent?: string;
}

/**
 * Needed this to allow passing button attributes to Button component
 */
type RestButtonProps = Exclude<
  keyof ButtonHTMLAttributes<HTMLButtonElement>,
  'type' | 'className' | 'disabled' | 'onClick' | 'size'
>;
type RestAnchorProps = Exclude<
  keyof AnchorHTMLAttributes<HTMLAnchorElement>,
  'type' | 'className' | 'disabled' | 'onClick' | 'size'
>;
const paddings: { [key in ButtonSize]: string } = {
  lg: 'px-6 pb-3.5 pt-3 ',
  md: 'px-5 pt-2 pb-2.5 ',
  sm: 'px-3.5 pt-1.3 pb-1.5 ',
  xs: 'px-2.5 pt-0.5 pb-[0.23rem] text-sm',
};

const borderRadius: { [key in ButtonSize]: string } = {
  lg: 'rounded-lg',
  md: 'rounded-md',
  sm: 'rounded-md',
  xs: 'rounded-[0.3rem]',
};

const backgrounds: { [key in ButtonType]: string } = {
  outline: 'bg-transparent',
  colorOutline: 'bg-transparent',
  solid: 'bg-action-primary',
  text: 'bg-transparent',
  secondary: 'bg-action-secondary',
  destructive: 'bg-transparent',
  blue: 'bg-decorative-blue',
  pink: 'bg-brand-pink/70',
  premium: 'bg-premium-800',
  white: 'bg-white',
  yellow: 'bg-brand-yellow',
  fancy: 'bg-gradient-to-r from-brand-yellow to-brand-pink',
  fancyBlue: 'bg-gradient-to-r from-decorative-blue to-decorative-pink',
  light: 'bg-surface',
};

const foregrounds: { [key in ButtonType]: string } = {
  outline: 'text-type-primary border-border-dark',
  colorOutline: 'text-type-accent border-accent',
  solid: 'text-white',
  premium: 'text-white',
  blue: 'text-type-primary',
  white: 'text-type-primary',
  pink: 'text-type-primary',
  yellow: 'text-type-primary',
  text: 'text-type-primary',
  secondary: 'text-type-primary',
  destructive: 'text-action-destructive border-action-destructive ',
  fancy: 'text-type-primary',
  fancyBlue: 'text-type-primary',
  light: 'text-type-subdued',
};

const hovers: { [key in ButtonType]: string } = {
  outline: 'hover:border-action-hover hover:text-action-hover',
  colorOutline: 'hover:border-accent/60',
  blue: 'hover:bg-brand-blue/80',
  pink: 'hover:bg-brand-pink/80',
  yellow: 'hover:bg-brand-yellow/80',
  solid: 'hover:bg-action-hover',
  premium: 'hover:bg-premium-700',
  text: 'hover:bg-action-hover-background hover:text-action-hover',
  white: 'hover:bg-action-secondary-hover',
  secondary: 'hover:bg-action-secondary-hover hover:text-action-hover',
  fancy: 'hover:from-brand-yellow/90 hover:to-brand-pink/90',
  fancyBlue: 'hover:from-brand-blue/90 hover:to-brand-pink/90',
  destructive:
    'hover:bg-action-destructive-hover-background hover:text-action-destructive-hover hover:border-action-destructive-hover',
  light: 'hover:bg-action-secondary-hover hover:text-action-hover',
};

const disables: { [key in ButtonType]: string } = {
  outline: 'text-type-disabled border-action-primary-disabled',
  colorOutline: 'text-type-disabled border-action-primary-disabled',
  blue: 'bg-brand-blue-25',
  pink: 'bg-brand-pink-25',
  premium: 'bg-premium-500',
  yellow: 'bg-brand-yellow-25',
  solid: 'text-white bg-action-primary-disabled',
  white: 'text-type-disabled',
  text: 'text-type-disabled',
  fancy:
    'text-type-primary/30 bg-gradient-to-r from-brand-yellow/40 to-brand-pink/40',
  fancyBlue:
    'text-type-primary/30 bg-gradient-to-r from-brand-blue/40 to-brand-pink/40',
  secondary: 'text-type-disabled bg-action-secondary-disabled',
  destructive: 'text-type-disabled border-action-primary-disabled',
  light: 'text-type-disabled bg-surface-light',
};

const borders: { [key in ButtonType]: string } = {
  outline: 'border-solid border-2 box-border',
  colorOutline: 'border-solid border-2 box-border',
  solid: '',
  secondary: '',
  blue: '',
  pink: '',
  white: '',
  yellow: '',
  text: '',
  premium: '',
  destructive: 'border-2 box-border',
  light: '',
  fancy: '',
  fancyBlue: '',
};

const iconColors: { [key in ButtonType]: IconColor } = {
  outline: 'black',
  colorOutline: 'accent',
  solid: 'white',
  premium: 'white',
  secondary: 'black',
  blue: 'black',
  white: 'black',
  pink: 'black',
  yellow: 'black',
  text: 'black',
  destructive: 'pink',
  light: 'black',
  fancy: 'black',
  fancyBlue: 'black',
};

const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps & {
    [key in RestButtonProps]?: ButtonHTMLAttributes<HTMLButtonElement>[key];
  } & {
    [key in RestAnchorProps]?: AnchorHTMLAttributes<HTMLAnchorElement>[key];
  }
>((props, ref) => {
  const {
    children,
    className,
    disabled = false,
    icon,
    link,
    linkTarget,
    loading,
    rounded,
    onClick,
    size = 'md',
    type = 'solid',
    iconLocation = 'right',
    htmlType = 'button',
    shadow = false,
    customPadding,
    iconClassName,
    customBorder,
    ...rest
  } = props;
  const refProps = ref ? { ref } : {};
  const classes = classNames(
    'inline-flex items-center justify-center font-bold min-w-min focus:outline-none focus-visible:outline-blue user-select-none gap-2 relative',
    {
      [paddings[size]]: !customPadding,
      [borderRadius[size]]: !customBorder && !rounded,
      [borders[type]]: !customBorder,
    },
    { [disables[type]]: disabled },
    { [backgrounds[type]]: !disabled },
    { [foregrounds[type]]: !disabled },
    { [hovers[type]]: !disabled && !loading },
    { 'cursor-not-allowed': disabled || loading },
    { 'text-sm': size === 'sm' },
    { 'shadow-sm': shadow },
    { 'rounded-full': rounded },
    { 'flex-row-reverse': iconLocation === 'left' },
    className,
  );

  const renderContent = () => {
    return (
      <>
        {loading ? <span className="opacity-0">{children}</span> : children}

        {icon ? (
          <Icon
            className={iconClassName}
            icon={icon}
            color={disabled ? 'disabled' : iconColors[type]}
          />
        ) : (
          ''
        )}

        {loading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Icon
              className={`animate-spin ${size === 'sm' ? 'w-4' : 'w-6'}`}
              icon={<Spinner />}
              color={iconColors[type]}
            />
          </div>
        )}
      </>
    );
  };

  const handleLinkClick: React.MouseEventHandler = (e) => {
    if (disabled || loading) return false;
    if (props.confirm) {
      if (!confirm(props.confirm)) {
        e.preventDefault();
        return false;
      }
    }
    return true;
  };

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (disabled || loading) return false;
    if (props.confirm) {
      if (confirm(props.confirm)) {
        onClick?.(e);
      }
    } else if (onClick) {
      onClick(e);
    }
  };

  const renderLink = () => (
    <a
      {...rest}
      ref={ref as React.RefObject<HTMLAnchorElement>}
      href={link}
      target={linkTarget ?? rest.target}
      onClick={handleLinkClick}
      className={classes}
    >
      {renderContent()}
    </a>
  );

  const renderButton = () => (
    // eslint-disable-next-line react/button-has-type
    <button
      {...rest}
      ref={ref as React.RefObject<HTMLButtonElement>}
      type={htmlType}
      className={classes}
      onClick={handleClick}
      disabled={disabled}
    >
      {renderContent()}
    </button>
  );

  return link ? renderLink() : renderButton();
});

Button.displayName = 'Button';

export default Button;
