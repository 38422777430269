/** @format */
import i18n from 'i18next';

import React, {
  createContext,
  useEffect,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';
import type { WizardItem, WizardItemsResponse, WizardItemType } from './types';

import { apiRequest } from 'blackbird/helpers/apiRequestHelper';
import { RequestActions } from 'javascripts/flux/actions/request';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
const errorHandler = RequestErrorHandler('Wizard');

const initWizardItems = i18n.t('wizard:items', {
  returnObjects: true,
}) as WizardItem[];

export interface WizardContextProps {
  handleFetch: () => void;
  handleSkip: (item_id: WizardItemType) => void;
  handleComplete: (item_id: WizardItemType, callback?: () => void) => void;
  wizardItems: WizardItem[] | undefined;
  setWizardItems: Dispatch<SetStateAction<WizardItem[] | undefined>>;
  wizardItemsToDo: WizardItem[] | undefined;
  setWizardItemsToDo: Dispatch<SetStateAction<WizardItem[] | undefined>>;
  wizardItemsSkipped: WizardItem[] | undefined;
  setWizardItemsSkipped: Dispatch<SetStateAction<WizardItem[] | undefined>>;
  wizardItemsComplete: WizardItem[] | undefined;
  setWizardItemsComplete: Dispatch<SetStateAction<WizardItem[] | undefined>>;
  isAvailable: boolean;
  setIsAvailable: Dispatch<SetStateAction<boolean>>;
  isComplete: boolean;
  setIsComplete: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isLoaded: boolean;
  setIsLoaded: Dispatch<SetStateAction<boolean>>;
  percentageComplete: number;
  setPercentageComplete: Dispatch<SetStateAction<number>>;
  tasksRemaining: number;
  setTasksRemaining: Dispatch<SetStateAction<number>>;
}

const defaultValues: WizardContextProps = {
  wizardItemsToDo: undefined,
  setWizardItemsToDo: () => {},
  wizardItemsSkipped: undefined,
  setWizardItemsSkipped: () => {},
  wizardItemsComplete: undefined,
  setWizardItemsComplete: () => {},
  wizardItems: undefined,
  setWizardItems: () => {},
  isAvailable: false,
  setIsAvailable: () => {},
  isComplete: false,
  setIsComplete: () => {},
  isLoaded: false,
  setIsLoaded: () => {},
  isOpen: false,
  setIsOpen: () => {},
  percentageComplete: 0,
  setPercentageComplete: () => {},
  tasksRemaining: 1,
  setTasksRemaining: () => {},
  handleFetch: () => {},
  handleSkip: () => {},
  handleComplete: () => {},
};

export const WizardContext = createContext<WizardContextProps>(defaultValues);

export const WizardProvider: React.FC = ({ children }) => {
  const [percentageComplete, setPercentageComplete] = useState(
    defaultValues.percentageComplete,
  );
  const [tasksRemaining, setTasksRemaining] = useState(
    defaultValues.tasksRemaining,
  );
  const [isOpen, setIsOpen] = useState(defaultValues.isOpen);
  const [isLoaded, setIsLoaded] = useState(defaultValues.isLoaded);
  const [isComplete, setIsComplete] = useState(defaultValues.isComplete);
  const [isAvailable, setIsAvailable] = useState(defaultValues.isAvailable);
  const [wizardItems, setWizardItems] = useState(initWizardItems);

  const [wizardItemsToDo, setWizardItemsToDo] = useState<
    WizardItem[] | undefined
  >(defaultValues.wizardItemsToDo);
  const [wizardItemsSkipped, setWizardItemsSkipped] = useState<
    WizardItem[] | undefined
  >(defaultValues.wizardItemsSkipped);
  const [wizardItemsComplete, setWizardItemsComplete] = useState<
    WizardItem[] | undefined
  >(defaultValues.wizardItemsComplete);

  useEffect(() => {
    if (BoordsConfig.IsProfessionalFree) {
      setIsAvailable(true);
    } else {
      setIsAvailable(false);
    }
  }, []);

  useEffect(() => {
    if (wizardItemsToDo) {
      setTasksRemaining(wizardItemsToDo.length);
    } else {
      setTasksRemaining(0);
    }
  }, [wizardItemsToDo]);

  useEffect(() => {
    const completed = wizardItems.filter(
      (i) => i.isComplete || i.isSkipped,
    ).length;
    setPercentageComplete((completed / wizardItems.length) * 100);
  }, [wizardItems]);

  useEffect(() => {
    setIsComplete(percentageComplete === 100);
  }, [percentageComplete]);

  useEffect(() => {
    if (isComplete) {
      Track.once.defer(`wizard_complete`, {
        posthogCapture: true,
        completeCount: wizardItemsComplete ? wizardItemsComplete.length : 0,
        skippedCount: wizardItemsSkipped ? wizardItemsSkipped.length : 0,
      });
    }
  }, [isComplete, wizardItemsSkipped, wizardItemsComplete]);

  useEffect(() => {
    setWizardItemsSkipped(wizardItems.filter((w) => w.isSkipped));
    setWizardItemsComplete(wizardItems.filter((w) => w.isComplete));
    setWizardItemsToDo(
      wizardItems.filter((w) => !w.isComplete && !w.isSkipped),
    );
  }, [wizardItems]);

  const findWizardItem = (item_id: WizardItemType): WizardItem | undefined => {
    return wizardItems.find((item) => item.id === item_id);
  };

  const handleFetch = React.useCallback(async () => {
    if (!isComplete) {
      const request = await apiRequest({
        path: `wizard`,
        method: 'get',
      });

      if (!request.ok) return errorHandler({ method: 'get' })(request);
      const response: WizardItemsResponse = await request.json();

      const { items } = response.data.attributes;
      setWizardItems(items);
      setIsLoaded(true);
    }
  }, [isComplete]);

  const handleSkip = async (item_id: WizardItemType) => {
    const request = await apiRequest({
      path: `wizard/${item_id}`,
      method: 'put',
      payload: {
        isSkipped: true,
      },
    });

    if (!request.ok) return errorHandler({ method: 'put' })(request);

    const response: WizardItemsResponse = await request.json();

    const { items } = response.data.attributes;
    setWizardItems(items);

    Track.event.defer(`wizard_item_skipped`, {
      posthogCapture: true,
      itemType: item_id,
    });

    RequestActions.success.defer(`Task skipped`);
  };

  const handleComplete = React.useCallback(
    async (item_id: WizardItemType, callback?: () => void) => {
      const taskComplete = wizardItems.find(
        (item) => item.id === item_id && item.isComplete,
      );

      if (isAvailable && !isComplete && !taskComplete) {
        const request = await apiRequest({
          path: `wizard/${item_id}`,
          method: 'put',
          payload: {
            isComplete: true,
          },
        });

        if (!request.ok) return errorHandler({ method: 'put' })(request);
        const response: WizardItemsResponse = await request.json();

        const { items } = response.data.attributes;
        setWizardItems(items);

        const item = findWizardItem(item_id);
        if (item) {
          // RequestActions.success.defer(`Quick start task complete!`);

          Track.event.defer(`wizard_item_complete`, {
            posthogCapture: true,
            itemType: item_id,
          });
        }
        if (callback) {
          callback();
        }
      }
    },
    [isAvailable, isComplete, wizardItems],
  );

  useEffect(() => {
    if (isAvailable && BoordsConfig.Uid) {
      handleFetch();
    }
  }, [isAvailable]);

  const values = {
    tasksRemaining,
    setTasksRemaining,
    percentageComplete,
    setPercentageComplete,
    wizardItems,
    setWizardItems,
    isOpen,
    setIsOpen,
    handleFetch,
    handleSkip,
    handleComplete,
    wizardItemsToDo,
    setWizardItemsToDo,
    wizardItemsSkipped,
    setWizardItemsSkipped,
    wizardItemsComplete,
    setWizardItemsComplete,
    isComplete,
    setIsComplete,
    isAvailable,
    setIsAvailable,
    isLoaded,
    setIsLoaded,
  };

  return (
    <WizardContext.Provider value={values}>{children}</WizardContext.Provider>
  );
};
