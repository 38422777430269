/** @format */
import React, {
  createContext,
  useEffect,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { useStore } from 'javascripts/helpers/useStore';
import { StoryboardStartersActions } from 'javascripts/flux/actions/storyboard_starters';
import { StoryboardActions } from 'javascripts/flux/actions/storyboard';
import { RequestActions } from 'javascripts/flux/actions/request';
import {
  quizSteps,
  type QuizStep,
  type QuizOption,
  type OptionId,
} from './QuizData';
import { UserActions } from 'javascripts/flux/actions/user';

interface QuizContextProps {
  projectId: number;
  quizSteps: QuizStep[];
  currentStep: QuizStep | undefined;
  setCurrentStep: Dispatch<SetStateAction<QuizStep | undefined>>;
  selectedOptions: { [key: string]: OptionId };
  setSelectedOptions: Dispatch<SetStateAction<{ [key: string]: OptionId }>>;
  selectedOption: QuizOption | null;
  setSelectedOption: Dispatch<SetStateAction<QuizOption | null>>;
  isMarketingOptIn: boolean;
  setIsMarketingOptIn: Dispatch<SetStateAction<boolean>>;
  isTeam: boolean;
  setIsTeam: Dispatch<SetStateAction<boolean>>;
  isAi: boolean;
  setIsAi: Dispatch<SetStateAction<boolean>>;
  industry: OptionId | undefined;
  setIndustry: Dispatch<SetStateAction<OptionId>>;
  role: string;
  setRole: Dispatch<SetStateAction<string>>;
  proceed: boolean;
  setProceed: Dispatch<SetStateAction<boolean>>;
  handleProceed: () => void;
}

export const QuizContext = createContext<QuizContextProps>({
  projectId: -1,
  quizSteps: quizSteps,
  currentStep: quizSteps[0],
  setCurrentStep: () => {},
  selectedOptions: {},
  setSelectedOptions: () => {},
  selectedOption: null,
  setSelectedOption: () => {},
  isMarketingOptIn: false,
  setIsMarketingOptIn: () => {},
  isTeam: false,
  setIsTeam: () => {},
  isAi: true,
  setIsAi: () => {},
  role: '',
  setRole: () => {},
  industry: undefined,
  setIndustry: () => {},
  proceed: false,
  setProceed: () => {},
  handleProceed: () => {},
});

interface QuizProviderProps {
  children: React.ReactNode;
  projectId: number;
}

export const QuizProvider: React.FC<QuizProviderProps> = ({
  children,
  projectId,
}) => {
  const [currentStep, setCurrentStep] = useState<QuizStep | undefined>(
    quizSteps[0],
  );
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: OptionId;
  }>({});
  const [selectedOption, setSelectedOption] = useState<QuizOption | null>(null);
  const [isTeam, setIsTeam] = useState(false);
  const [isAi, setIsAi] = useState(true);
  const [isMarketingOptIn, setIsMarketingOptIn] = useState(false);
  const [role, setRole] = useState('Creators');
  const [industry, setIndustry] = useState<OptionId | undefined>(undefined);
  const [proceed, setProceed] = useState(false);

  const starters = useStore('storyboardStarters', (s) => s.starters);
  const isLoading = useStore('storyboardStarters', (s) => s.isLoading);

  const saveRole = (slug: string) => {
    Track.event.defer(`uc_${slug}`);
    Track.event.defer('ob_audience', {
      category: 'Onboarding',
      label: slug,
    });
    UserActions.updateFields.defer({
      account_type: slug,
    });
  };

  useEffect(() => {
    if (role && currentStep && currentStep.id === 'step-industry') {
      saveRole(role.toLowerCase().slice(0, -1));
    }
  }, [role, currentStep]);

  useEffect(() => {
    if (industry && currentStep && currentStep.id === 'step-goals') {
      Track.event.defer('ob_industry', {
        category: 'Onboarding',
        label: industry.replace('option-industry-', ''),
      });
    }
  }, [industry, currentStep]);

  // Save quiz responses to db
  useEffect(() => {
    if (role && currentStep && currentStep.id === 'step-loading') {
      Track.event.defer('ob_ai', {
        category: 'Onboarding',
        label: isAi,
      });

      Track.event.defer('ob_team', {
        category: 'Onboarding',
        label: isTeam,
      });

      Track.event.defer('ob_newsletter', {
        category: 'Onboarding',
        label: isMarketingOptIn,
      });

      UserActions.updateFields.defer({
        is_marketing_opt_in: isMarketingOptIn,
        quiz_items: JSON.stringify({
          role: role.toLowerCase().slice(0, -1),
          isAi,
          isTeam,
        }),
      });
    }
  }, [currentStep, role, isAi, isTeam, isMarketingOptIn]);

  useEffect(() => {
    if (selectedOptions) {
      setIsAi(selectedOptions['step-goals'] === 'option-goals-ai');
      setIsTeam(selectedOptions['step-team'] === 'option-collaboration-yes');
      setIsMarketingOptIn(
        selectedOptions['step-newsletter'] === 'option-newsletter-yes',
      );
      setIndustry(selectedOptions['step-industry']);

      let newRole = '';
      switch (selectedOptions['step-role']) {
        case 'option-role-creator':
          newRole = 'Creators';
          break;
        case 'option-role-founder':
          newRole = 'Founders';
          break;
        case 'option-role-producer':
          newRole = 'Producers';
          break;
        case 'option-role-designer':
          newRole = 'Designers';
          break;
        default:
          newRole = '';
      }
      setRole(newRole);
    }
  }, [selectedOptions]);

  useEffect(() => {
    StoryboardStartersActions.fetch.defer();
  }, []);

  // Automatically proceed
  useEffect(() => {
    if (
      !isLoading &&
      starters &&
      Object.keys(starters).length === 1 &&
      currentStep &&
      currentStep.id === 'step-loading'
    ) {
      // if (isAi) {
      //   setCurrentStep(quizSteps.find((step) => step.id === 'step-generate'));
      // } else {
      setProceed(true);
      // }
    }
  }, [starters, isLoading, currentStep]);

  const handleProceed = () => {
    window.location.href = '/';
  };

  useEffect(() => {
    if (proceed) {
      const selectedStarter = starters[Object.keys(starters)[0]];
      const payload = {
        documentName: 'Untitled Storyboard',
        projectId: projectId,
        blank_frame_count: 0,
        track_first_storyboard: true,
        starter_storyboard_id: undefined,
        generateThumbnails: false,
        callbackOnly: true,
        callback: (success: boolean) => {
          if (success) {
            setTimeout(() => {
              handleProceed();
              // setCurrentStep(
              //   quizSteps.find((step) => step.id === 'step-offer'),
              // );
              // if (isAi || discountVariant === "discount") {
              // } else {
              //   setCurrentStep(
              //     quizSteps.find((step) => step.id === 'step-pricing'),
              //   );
              // }
            }, 2000);
          } else {
            RequestActions.error.defer(`Error creating storyboard`);
          }
        },
      };

      if (selectedStarter && isAi) {
        Object.assign(payload, {
          documentName: selectedStarter.new_storyboard_name,
          starter_storyboard_id: selectedStarter.storyboard_id,
        });
      }
      // Track the event
      Track.event.defer('ob_starter', {
        category: 'Onboarding',
        template: selectedStarter.slug,
      });

      StoryboardActions.create.defer(payload);
    }
  }, [projectId, proceed, isAi, isTeam]);

  const values = {
    quizSteps,
    currentStep,
    setCurrentStep,
    selectedOptions,
    setSelectedOptions,
    selectedOption,
    setSelectedOption,
    isTeam,
    setIsTeam,
    isAi,
    setIsAi,
    role,
    setRole,
    proceed,
    setProceed,
    handleProceed,
    isMarketingOptIn,
    setIsMarketingOptIn,
    projectId,
    industry,
    setIndustry,
  };

  return <QuizContext.Provider value={values}>{children}</QuizContext.Provider>;
};
