/** @prettier */

import * as React from 'react';
import Button from 'blackbird/components/button/Button';
import Banner from 'blackbird/components/feedback/banner/Banner';
import classNames from 'classnames';
import { CalendarIcon } from 'lucide-react';
import { differenceInDays, endOfDay } from 'date-fns';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';

declare const BoordsConfig: {
  TrialEndsAt?: number;
};

export const BannerPaidTrial: React.FC = () => {
  const getDaysRemaining = () => {
    if (!BoordsConfig.TrialEndsAt) {
      return null;
    }
    const now = new Date();
    const trialEndDate = new Date(BoordsConfig.TrialEndsAt * 1000);
    const daysRemaining = differenceInDays(endOfDay(trialEndDate), now);
    return daysRemaining <= 0 ? null : daysRemaining;
  };

  const daysRemaining = getDaysRemaining();

  if (daysRemaining === null) {
    return null;
  }

  return (
    <Banner
      kind={'alert'}
      hideIcon
      className="no-padding-y h-banner bg-premium-100"
      customWidth
      message={
        <div className="relative flex items-center justify-start flex-auto w-full">
          <Tooltip
            placement="bottom-start"
            title={`View trial status, upgrade, or cancel`}
          >
            <a
              className="flex items-center pl-3 pr-3.5 pt-0.5 pb-1 leading-tight font-semibold rounded-md bg-premium-200 text-premium-700 cursor-pointer hover:bg-premium-300 hover:text-premium-800"
              href="/billing"
            >
              <CalendarIcon className="w-3.5 mt-[0.1rem]" />
              <span className="ml-1.5">{`Free Trial`}</span>
            </a>
          </Tooltip>
          <div className="ml-3 mr-2.5 font-semibold text-premium-800">
            {`${daysRemaining} days remaining`}
          </div>
        </div>
      }
    />
  );
};
