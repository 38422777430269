/** @format */

import { GiftIcon, XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { WizardContext } from './WizardContext';
import { WizardListItem } from './WizardListItem';
import { WizardProgress } from './WizardProgress';

interface WizardItemsProps {
  closeable?: boolean;
}

export const WizardItems: React.FC<WizardItemsProps> = ({ closeable }) => {
  const {
    wizardItemsSkipped,
    wizardItemsToDo,
    wizardItemsComplete,
    isComplete,
    wizardItems,
    setIsOpen,
  } = useContext(WizardContext);

  interface WizardListTitleProps {
    title: string;
  }

  useEffect(() => {
    Track.event.defer(`wizard_open`, {
      posthogCapture: true,
    });
  }, []);

  const WizardListTitle: React.FC<WizardListTitleProps> = ({ title }) => (
    <div className="mb-2 text-xs uppercase text-type-subdued tracking-loose">
      {title}
    </div>
  );

  return !wizardItems || isComplete ? null : (
    <div
      className={classNames(
        'border border-decorative-blue/50 rounded-lg bg-white',
      )}
    >
      <div className="px-6 py-5 ">
        <div className="flex items-center mb-2 ">
          <div
            className={classNames(
              'flex-auto font-semibold flex items-center',
              closeable ? 'text-lg ' : 'text-xl',
            )}
          >
            <span>{`Quick Start`}</span>
          </div>
          {closeable && (
            <span
              className="flex-shrink-0 cursor-pointer hover:text-type-primary text-type-disabled"
              onClick={() => setIsOpen(false)}
            >
              <XMarkIcon className="w-5" />
            </span>
          )}
        </div>

        <div className="flex items-center mb-8">
          <div className="flex-shrink-0 inline-block my-3 text-center space-t-2">
            <WizardProgress large />
            <div className="text-sm text-type-subdued">{`still to go`}</div>
          </div>

          <div className="flex-auto mt-0 ml-4 text-sm space-x-1">
            <span>{`Complete Quick Start tasks to`}</span>
            <span>{`learn why Boords is the`}</span>
            <span>{`shortcut to effective storyboards.`}</span>
          </div>
        </div>

        <div className="space-y-4">
          {wizardItemsToDo && wizardItemsToDo.length > 0 && (
            <div>
              <WizardListTitle title={`What's next?`} />
              <div className="space-y-2">
                {wizardItemsToDo.map((item, idx: number) => {
                  return idx > 0 ? null : (
                    <WizardListItem key={item.id} item={item} expanded />
                  );
                })}
              </div>
            </div>
          )}

          {wizardItemsToDo && wizardItemsToDo.length > 1 && (
            <div>
              <WizardListTitle title={`To Do`} />

              <div className="space-y-2">
                {wizardItemsToDo.map((item, idx: number) => {
                  return idx > 0 ? (
                    <WizardListItem key={item.id} item={item} />
                  ) : null;
                })}
              </div>
            </div>
          )}

          {wizardItemsComplete && (
            <div>
              <WizardListTitle title={`Complete`} />
              <div className="space-y-2">
                {wizardItemsComplete.map((item) => (
                  <WizardListItem key={item.id} item={item} />
                ))}
              </div>
            </div>
          )}

          {wizardItemsSkipped && wizardItemsSkipped.length > 0 && (
            <div>
              <WizardListTitle title={`Skipped`} />
              <div className="space-y-2">
                {wizardItemsSkipped.map((item) => (
                  <WizardListItem key={item.id} item={item} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
