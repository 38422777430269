/**@prettier */
const createReactClass = require('create-react-class');
var Stringex = require('stringex');

window.CoverpageDocument = createReactClass({
  getInitialState: function () {
    return CoverpageStore.getState();
  },

  componentDidMount: function () {
    CoverpageStore.listen(this._onChange);
  },

  componentWillUnmount: function () {
    CoverpageStore.unlisten(this._onChange);
  },

  _onChange: function (state) {
    this.setState(state);
  },

  handleButtonClick: function (e) {
    e.preventDefault();
    FilestackActions.openPdfCoverLogo({
      team_id: this.state.cover.storyboard.team_id,
    });
  },

  render: function () {
    var documentStyles, buttonClass, logo;

    if (!this.state.cover) {
      return <div />;
    }

    documentStyles = {
      color: this.props.foregroundColor,
      background: this.props.backgroundColor,
    };

    if (this.state.preview_logo_url) {
      logo = this.state.preview_logo_url;
    } else {
      logo = this.state.cover.theme.logo_url;
    }

    var logoClassName = this.state.cover.theme.logo_is_processing
      ? ' logo--processing'
      : ' logo--processed';
    var disableClick;
    if (this.state.cover.theme.logo_is_processing) {
      disableClick = true;
    } else {
      disableClick = false;
    }

    var fileName = Stringex.removeFormatting(
        this.state.cover.storyboard.document_name,
      ),
      name = this.state.cover.storyboard.document_name;

    if (this.state.cover.storyboard.include_version_number) {
      fileName = fileName + ' ' + this.state.cover.storyboard.version_name;
      name = name + ' ' + this.state.cover.storyboard.version_name;
    }

    fileName = Stringex.replaceWhitespace(fileName, '_');
    fileName = Stringex.collapse(fileName, '_');

    return (
      <div className="self-start cpbuilder--document-container">
        <div
          className="relative overflow-hidden h-[236px] w-[330px] cpbuilder--document flex flex-col rounded items-center justify-center border border-border-image"
          style={documentStyles}
        >
          <div className="cpbuilder--document-logo relative">
            <button
              type="button"
              className={
                'cpbuilder--dropzone  relative max-w-[13rem] p-8 group' +
                logoClassName
              }
              onClick={this.handleButtonClick}
            >
              <div className="absolute inset-0 group-hover:flex items-end  hidden z-10 bg-black bg-opacity-[0.12]">
                <div className="bg-black bg-opacity-[0.16] text-white text-center flex-grow text-sm">
                  Replace (500px min)
                </div>
              </div>
              <img src={logo} />
            </button>
          </div>
          <div className="cpbuilder--document-footer absolute left-0 right-0 bottom-0 p-4">
            <div className="cpbuilder--document-footer-label font-bold">
              {name}
            </div>
            <div className="cpbuilder--document-date float-right">
              {this.state.cover.storyboard.document_secondary_info}
            </div>
          </div>
        </div>
        <div className="cpbuilder--document-caption hidden">
          {fileName + '.pdf'}
        </div>
      </div>
    );
  },
});
