/** @format */

import classNames from 'classnames';
import qs from 'querystringify';
import React, { useContext } from 'react';
import { WizardContext } from './WizardContext';
import { WizardItems } from './WizardItems';
import { WizardProgress } from './WizardProgress';
import Popover from 'blackbird/components/common/popover/Popover';
import logger from 'javascripts/helpers/logger';

interface WizardDropdownContainerProps {
  small?: boolean;
}

export const WizardDropdownContainer: React.FC<
  WizardDropdownContainerProps
> = ({ small = false }) => {
  const { isComplete, wizardItems, isLoaded, isAvailable } =
    useContext(WizardContext);

  const [portalOpen, setPortalOpen] = React.useState<boolean | undefined>(
    undefined,
  );

  React.useEffect(() => {
    const { openWizard } = qs.parse(window.location.search);
    if (openWizard) {
      history.replaceState(undefined, document.title, location.pathname);
      setTimeout(() => {
        setPortalOpen(true);
      }, 500);
    } else {
      setPortalOpen(undefined);
    }
  }, []);

  const handlPopoverButtonClick = React.useCallback(() => {
    if (portalOpen) {
      setPortalOpen(undefined);
    }
  }, [portalOpen]);

  return !wizardItems || isComplete || !isAvailable || !isLoaded ? null : (
    <Popover placement="bottom-end" distance={10} portal isOpen={portalOpen}>
      <Popover.Button as="div">
        <div
          onClick={handlPopoverButtonClick}
          className={classNames(
            `relative flex items-center bg-decorative-blue/30 border-2 border-decorative-blue/80 cursor-pointer hover:bg-decorative-blue/20 rounded-md`,
            small ? 'py-[0.2rem] pl-1.5 pr-3 ' : 'py-[0.3rem] pl-2.5 pr-5 ',
          )}
        >
          <div className={classNames('flex-shink-0')}>
            <WizardProgress small={small} />
          </div>
          <div
            className={classNames(
              'flex-auto leading-tight',
              small ? 'ml-2' : 'ml-2.5',
            )}
          >
            <div
              className={classNames(
                'font-semibold whitespace-nowrap',
                small ? 'text-sm -mt-0.5' : 'text-base -mt-1 ',
              )}
            >{`Quick Start`}</div>
            {/* <div className="-mt-0.5 text-xs font-semibold text-type-subdued">{`${tasksRemaining} still to go`}</div> */}
          </div>
        </div>
      </Popover.Button>
      <Popover.Panel className={'z-header'}>
        <div className="w-[24rem]">
          <WizardItems />
        </div>
      </Popover.Panel>
    </Popover>
  );
};
