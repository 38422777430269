/** @format */
import {
  Building,
  ImageUp,
  Layers,
  ListChecks,
  ThumbsDown,
  ThumbsUp,
  User,
  Users,
  Wallpaper,
  WandSparkles,
} from 'lucide-react';

import React from 'react';
import { QuizCard } from './QuizCard';
import { QuizProof } from './QuizProof';
import { QuizGenerate } from './QuizGenerate';
import { QuizPricing } from './QuizPricing';
import { LoadingIndicator } from '../common/loading-indicator/LoadingIndicator';
import { QuizOffer } from './QuizOffer';
import { QuizPassword } from './QuizPassword';

export interface QuizOption {
  id: OptionId;
  text: React.ReactNode;
  nextStepId: StepId | null;
  callback?: () => void;
}

export interface QuizStep {
  id: StepId;
  component?: React.ReactNode;
  title: string;
  description?: React.ReactNode;
  containerClassName?: string;
  backStepId?: StepId;
  options?: QuizOption[];
  preselectedOptionId?: OptionId;
  buttonText?: string;
  nextStepId?: StepId;
}

export type StepId =
  | 'step-password'
  | 'step-role'
  | 'step-industry'
  | 'step-goals'
  | 'step-team'
  | 'step-newsletter'
  | 'step-proof'
  | 'step-generate'
  | 'step-loading'
  | 'step-offer'
  | 'step-pricing';
export type OptionId =
  | 'option-role-creator'
  | 'option-role-founder'
  | 'option-role-producer'
  | 'option-role-designer'
  | 'option-industry-media'
  | 'option-industry-marketing'
  | 'option-industry-tech'
  | 'option-industry-elearning'
  | 'option-industry-design'
  | 'option-industry-business'
  | 'option-industry-retail'
  | 'option-industry-education'
  | 'option-industry-other'
  | 'option-goals-ai'
  | 'option-goals-organize'
  | 'option-newsletter-yes'
  | 'option-newsletter-no'
  | 'option-collaboration-yes'
  | 'option-collaboration-no';

export const quizSteps: QuizStep[] = [
  {
    id: 'step-password',
    title: 'Choose a password',
    description: 'Passwords must be at least 8 characters long.',
    component: <QuizPassword />,
    buttonText: 'Continue →',
    nextStepId: 'step-role',
  },
  {
    id: 'step-role',
    title: `What's your role?`,
    containerClassName: 'max-w-3xl',
    description: 'Your answer helps us personalize Boords for you.',
    preselectedOptionId: 'option-role-creator',
    options: [
      {
        id: 'option-role-creator',
        text: (
          <QuizCard
            title={`Creator`}
            description={`I'm planning a video or creative project.`}
            icon={<Wallpaper />}
          />
        ),
        nextStepId: 'step-industry',
      },
      {
        id: 'option-role-founder',
        text: (
          <QuizCard
            title={`Founder`}
            description={`I run a creative studio or agency.`}
            icon={<Building />}
          />
        ),
        nextStepId: 'step-industry',
      },
      {
        id: 'option-role-producer',
        text: (
          <QuizCard
            title={`Producer`}
            description={`I manage video production projects.`}
            icon={<ListChecks />}
          />
        ),
        nextStepId: 'step-industry',
      },
      {
        id: 'option-role-designer',
        text: (
          <QuizCard
            title={`Designer`}
            description={`I design visuals professionally.`}
            icon={<Layers />}
          />
        ),
        nextStepId: 'step-industry',
      },
    ],
  },
  {
    id: 'step-industry',
    title: `What's your industry?`,
    containerClassName: 'max-w-3xl',
    description: 'Your answer helps us personalize Boords for you.',
    options: [
      {
        id: 'option-industry-media',
        text: <QuizCard title={`Media & Entertainment`} compact />,
        nextStepId: 'step-goals',
      },
      {
        id: 'option-industry-marketing',
        text: <QuizCard title={`Marketing & PR`} compact />,
        nextStepId: 'step-goals',
      },
      {
        id: 'option-industry-tech',
        text: <QuizCard title={`Technology`} compact />,
        nextStepId: 'step-goals',
      },
      {
        id: 'option-industry-elearning',
        text: <QuizCard title={`eLearning`} compact />,
        nextStepId: 'step-goals',
      },
      {
        id: 'option-industry-design',
        text: <QuizCard title={`Design & Creative`} compact />,
        nextStepId: 'step-goals',
      },
      {
        id: 'option-industry-business',
        text: <QuizCard title={`Business & Consulting`} compact />,
        nextStepId: 'step-goals',
      },
      {
        id: 'option-industry-retail',
        text: <QuizCard title={`Retail & eCommerce`} compact />,
        nextStepId: 'step-goals',
      },
      {
        id: 'option-industry-education',
        text: <QuizCard title={`Education`} compact />,
        nextStepId: 'step-goals',
      },
      {
        id: 'option-industry-other',
        text: <QuizCard title={`Other`} compact />,
        nextStepId: 'step-goals',
      },
    ],
  },
  {
    id: 'step-goals',
    title: 'Choose your path',
    description: `Use Boords to generate images or create storyboards using existing images. You can always change this later.`,
    preselectedOptionId: 'option-goals-ai',
    options: [
      {
        id: 'option-goals-ai',
        text: (
          <QuizCard
            title={`Create with AI`}
            description={`Use AI to generate storyboards.`}
            icon={<WandSparkles />}
          />
        ),
        nextStepId: 'step-newsletter',
      },
      {
        id: 'option-goals-organize',
        text: (
          <QuizCard
            title={`Use my images`}
            description={`Create storyboards with my own assets.`}
            icon={<ImageUp />}
          />
        ),
        nextStepId: 'step-newsletter',
      },
    ],
  },
  {
    id: 'step-team',
    title: 'Do you work on a team?',
    description: `Boords helps creative teams collaborate on storyboards.`,
    preselectedOptionId: 'option-collaboration-no',
    options: [
      {
        id: 'option-collaboration-no',
        text: <QuizCard title={`No, I work solo`} icon={<User />} />,
        nextStepId: 'step-proof',
      },
      {
        id: 'option-collaboration-yes',
        text: <QuizCard title={`Yes, I work on a team`} icon={<Users />} />,
        nextStepId: 'step-proof',
      },
    ],
  },
  {
    id: 'step-proof',
    title: 'We can help!',
    containerClassName: 'max-w-2xl',
    component: <QuizProof />,
    backStepId: 'step-goals',
    buttonText: "Let's go →",
    nextStepId: 'step-newsletter',
  },
  {
    id: 'step-newsletter',
    title: 'Get actionable storyboarding tips',
    description: `Join 130,000+ other video pros who receive our weekly newsletter packed with actionable pre-production tips.`,
    preselectedOptionId: 'option-newsletter-no',
    buttonText: 'Finish setup →',
    options: [
      {
        id: 'option-newsletter-no',
        text: <QuizCard title={`No thanks`} icon={<ThumbsDown />} />,
        nextStepId: 'step-loading',
      },
      {
        id: 'option-newsletter-yes',
        text: <QuizCard title={`Yes please!`} icon={<ThumbsUp />} />,
        nextStepId: 'step-loading',
      },
    ],
  },
  {
    id: 'step-loading',
    title: 'Creating account....',
    description: `You'll be redirected in a few seconds`,
    containerClassName: 'max-w-2xl',
    component: <LoadingIndicator />,
    backStepId: 'step-goals',
  },
  {
    id: 'step-generate',
    title: 'Generate your first storyboard',
    description: `Choose an example prompt...`,
    containerClassName: 'max-w-2xl',
    component: <QuizGenerate />,
    backStepId: 'step-goals',
    buttonText: "Let's go →",
    nextStepId: 'step-loading',
  },
  {
    id: 'step-offer',
    title: '',
    containerClassName: 'max-w-2xl',
    component: <QuizOffer />,
    backStepId: 'step-goals',
    buttonText: "Let's go →",
    nextStepId: 'step-pricing',
  },
  {
    id: 'step-pricing',
    title: 'Choose where to start',
    description: 'Last step! You can change or cancel your plan at any time.',
    containerClassName: 'max-w-screen',
    component: <QuizPricing />,
    backStepId: 'step-proof',
  },
];
