/** @prettier */
import * as React from 'react';

import { brushColorsValues } from '../frame_editor/frameEditorDefaults';
import { SuspenseLoader } from './SuspenseLoader';
import { IconSpacer } from '../IconSpacer';
import { rollbar } from '../../helpers/rollbar';
import { unique } from 'underscore';
import { userColoursLocalState } from '../../helpers/local-state';
import Popover from 'blackbird/components/common/popover/Popover';
import Icon from 'blackbird/components/icon/Icon';
import ArrowRightIcon from 'blackbird/images/icons/arrow-right.svg';
import { DialogContext } from 'blackbird/components/dialog/DialogContext';
import { openConfirmDialog } from 'javascripts/helpers/openDialog';

const SketchPicker = React.lazy(() => import('./SketchPicker'));
const fontLoader = require('../../helpers/font-loader');
const empty = [];

const boxShadow = 'inset 0 0 1px rgba(0,0,0,0.3)';
const activeBoxShadow = boxShadow + ', 0 0 3px rgba(134, 221,233, 0.9)';
const fontWeight = 400;
const fontStyles = { fontFamily: "'Source Code Pro', monospace", fontWeight };

interface Props {
  color?: string;
  label?: string;
  onChange: (newValue: string) => void;
  disabled?: boolean;
  updateOnChange?: boolean;
  width?: number | string;
  showUserSwatches?: boolean;
  icon?: React.ReactNode;
  showAlpha?: boolean;
}
export class ColorPicker extends React.Component<
  Props,
  {
    color: string;
    storedColors: string[];
    currentColorIsInSwatch: boolean;
  }
> {
  constructor(props) {
    super(props);
    let storedColors = brushColorsValues;

    try {
      storedColors = unique(userColoursLocalState.getValue() ?? storedColors);
    } catch (error) {
      rollbar.warn(error);
    }

    // This ensures we have a color, even if someone passes 'null' as a value
    const color = this.props.color || '#ffffff';

    this.state = {
      currentColorIsInSwatch: storedColors.indexOf(color) >= 0,
      color,
      storedColors,
    };
  }

  static defaultProps = {
    color: '#ffffff',
    width: 39,
    updateOnChange: true,
    showUserSwatches: true,
  };

  componentDidMount() {
    fontLoader.load('Source Code Pro', fontWeight);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.color || nextState.color !== this.state.color;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.color !== this.props.color) {
      this.setState({ color: this.props.color! });
    }
  }

  handleChange = (color) => {
    let hex = color.hex;

    // Not everything supports 8-character hex values, so we want to make sure
    // we don't pass them accidentally through a preset or something.
    if (color.rgb?.a && this.props.showAlpha)
      hex += Math.round(color.rgb.a * 255).toString(16);

    this.setState((state) => ({
      color: hex,
      currentColorIsInSwatch: state.storedColors.indexOf(hex) >= 0,
    }));

    if (this.props.updateOnChange) this.props.onChange(hex);
  };

  handleClose = () => {
    if (!this.props.updateOnChange) {
      this.props.onChange(this.state.color);
    }
  };

  handleAddColor = () =>
    this.setState((state) => {
      const storedColors = [...state.storedColors, state.color];

      userColoursLocalState.setValue(storedColors);

      return {
        currentColorIsInSwatch: true,
        storedColors,
      };
    });

  handleColorSwatchClick = (e: React.MouseEvent<HTMLButtonElement>) =>
    this.handleChange({ hex: e.currentTarget.value });

  static contextType = DialogContext;
  handleColorSwatchDoubleClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const color = e.currentTarget.value;
    if (
      await openConfirmDialog(
        `Do you want to delete this color? (${color})`,
        this.context,
      )
    ) {
      this.setState((state) => {
        const storedColors = state.storedColors.filter((i) => i !== color);

        userColoursLocalState.setValue(storedColors);

        return {
          storedColors,
          currentColorIsInSwatch: false,
        };
      });
    }
  };

  render() {
    const style = {
      width: this.props.width,
    };
    const bgStyle = { background: this.state.color };

    return (
      <div className="flex flex-col">
        {this.props.label ? (
          <label className="mb-1.5 font-bold">{this.props.label}</label>
        ) : null}

        <Popover
          disableMaxHeightModifier
          // disabled={this.props.disabled}
          placement="top"
          distance={10}
          onClose={this.handleClose}
        >
          <Popover.Button className="flex shrink-0">
            {this.props.icon ?? (
              <div
                className="rounded-sm h-[35px] fullsize:h-[39px] border border-form flex items-center p-2 justify-between flex-auto"
                style={style}
              >
                <div
                  className="w-6 h-6 border rounded-full border-border"
                  style={bgStyle}
                />
                <Icon icon={<ArrowRightIcon />} />
              </div>
            )}
          </Popover.Button>
          <Popover.Panel
            style={fontStyles}
            className="bg-white relative p-2.5 rounded-lg shadow-[0px_2px_4px_0px_rgba(0,0,0,0.3)] w-56 min-h-24"
          >
            <SuspenseLoader>
              <SketchPicker
                color={this.state.color}
                onChange={this.handleChange}
                width={204}
                presetColors={empty}
                disableAlpha={!this.props.showAlpha}
              />

              {this.props.showUserSwatches && (
                <>
                  <div className="border-t border-t-[#EAEAEA] absolute left-0 right-0" />
                  <div className="mt-2.5 bg-white">
                    <IconSpacer className="flex-wrap gap-2.5 flex">
                      {this.state.storedColors.map((color) => (
                        <button
                          key={color}
                          type="button"
                          className="w-4 h-4 rounded-sm"
                          onClick={this.handleColorSwatchClick}
                          onDoubleClick={this.handleColorSwatchDoubleClick}
                          value={color}
                          style={{
                            background: color,
                            boxShadow:
                              this.state.color === color
                                ? activeBoxShadow
                                : boxShadow,
                          }}
                        />
                      ))}

                      <button
                        type="button"
                        style={{ boxShadow }}
                        onClick={this.handleAddColor}
                        className={`w-4 h-4 rounded-sm flex items-center justify-center ${
                          this.state.currentColorIsInSwatch
                            ? 'opacity-40 cursor-not-allowed'
                            : ''
                        }`}
                        disabled={this.state.currentColorIsInSwatch}
                      >
                        +
                      </button>
                    </IconSpacer>
                  </div>
                </>
              )}
            </SuspenseLoader>
          </Popover.Panel>
        </Popover>
      </div>
    );
  }
}
