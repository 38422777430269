/** @format */

import React from 'react';

interface SecondaryLinkProps {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  children: React.ReactNode;
  href?: string;
  newWindow?: boolean;
}

export const SecondaryLink: React.FC<SecondaryLinkProps> = ({
  onClick,
  children,
  href,
  newWindow,
}) => {
  const linkClassNames =
    'text-sm font-normal underline cursor-pointer text-type-subdued decoration-border-mid hover:no-underline underline-offset-2';

  return href && newWindow ? (
    <a
      className={linkClassNames}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
    >
      {`${children} ↗`}
    </a>
  ) : href ? (
    <a className={linkClassNames} href={href}>
      {children}
    </a>
  ) : (
    <a className={linkClassNames} onClick={onClick}>
      {children}
    </a>
  );
};
