/** @prettier */
import * as React from 'react';
import { type ButtonProps } from 'blackbird/components/button/Button';
import { CopyFromStoryboard } from './CopyFieldsFromStoryboard';
import { type FrameField } from '../../types/storyboard';
import NewStoryboardAspectRatio from './NewStoryboardAspectRatio';
import NewStoryboardProjectsList from './NewStoryboardProjectsList';
import { advancedSettingsKey } from '../storyboard/Settings/StoryboardSettings';
import { StoryboardActions } from '../../flux/actions/storyboard';
import { LocalState } from '../../helpers/local-state';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import Dialog from 'blackbird/components/dialog/Dialog';
import { type WithTranslation, withTranslation } from 'react-i18next';
import { RequestActions } from 'javascripts/flux/actions/request';
import ToggleButtonWithLabel from '../shared/ToggleButtonWithLabel';
import { AiPrompt } from 'blackbird/components/ai/AiPrompt';
import { BoordsAiContext } from 'blackbird/helpers/contexts/BoordsAiContext';
import type { Project } from 'javascripts/flux/stores/dashboard';
import { isEmpty } from 'underscore';
import logger from 'javascripts/helpers/logger';
import { type WizardContextProps } from 'blackbird/components/wizard/WizardContext';
import { TourHintable } from '../tours/TourHintable';
import { type availableTourNames } from 'javascripts/flux/stores/tours';
import { ToursActions } from 'javascripts/flux/actions/tours';
import { SecondaryLink } from 'blackbird/components/common/SecondaryLink';

interface Props extends WithTranslation {
  show_project_dropdown: boolean;
  wizardContext: WizardContextProps;
  new_storyboard_project: any;
  frame_fields: FrameField[] | null;
  placeholder?: string;
  projects?: Project[];
  cta?: string;
  onClose?: () => void;
}

interface State {
  error?: string;
  isValid: boolean;
  isExpanded: boolean;
  storyboardName: string;
  // ID as a string
  starterId: string;
  isSaving: boolean;
}
class NewStoryboardForm extends React.PureComponent<Props, State> {
  nameFieldRef = React.createRef<HTMLInputElement>();

  static contextType = BoordsAiContext;
  context!: React.ContextType<typeof BoordsAiContext>;

  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
      isValid: false,
      storyboardName: '',
      starterId: '',
      isSaving: false,
      isExpanded: LocalState.getValue(advancedSettingsKey) ?? false,
    };
  }

  componentDidMount() {
    Track.event.defer('init_new_storyboard');

    setTimeout(
      function () {
        this.nameFieldRef.current?.focus();
      }.bind(this),
      300,
    );
  }

  handleChange = (e) => {
    const storyboardName = e.target.value;
    const isNameEmpty = isEmpty(storyboardName);

    this.setState({ storyboardName, isValid: !isNameEmpty });
    if (!isNameEmpty) {
      Track.once.defer('name_new_storyboard');
    }
  };

  handleStarterChange = (starterId: string) => {
    this.setState({ starterId });
  };

  createStoryboard = () => {
    this.setState({ error: undefined, isSaving: true });
    const { starterId, storyboardName } = this.state;
    // We return a promise so that we wait with hiding the dialog until we're
    // done
    return new Promise<void>((resolve, reject) => {
      if (storyboardName?.length) {
        StoryboardActions.create({
          // In case you're wondering, the aspect ratio is read inside the
          // store's `create` handler.
          documentName: storyboardName,
          projectId: this.props.new_storyboard_project.id,
          frame_fields: starterId
            ? undefined
            : this.props.frame_fields || undefined,
          generateThumbnails: starterId?.length > 0,
          starter_storyboard_id: starterId.length
            ? parseInt(this.state.starterId)
            : undefined,
          callback: (success) => {
            this.setState({ isSaving: false }, () => {
              if (success) {
                this.props.wizardContext.handleComplete('create-storyboard');
                return resolve();
              }
              return reject();
            });
          },
        });
      } else {
        this.setState({
          error: 'Please choose a name for your storyboard',
          isSaving: false,
        });
        RequestActions.error.defer('Please give your storyboard a name!');
      }
    });
  };

  handleToggleExpand = () => {
    this.setState((state) => {
      LocalState.setValue(advancedSettingsKey, !state.isExpanded);
      return { isExpanded: !state.isExpanded };
    });
  };

  handleConfirm = () => this.createStoryboard();

  tourIsActive = (tourName: availableTourNames) =>
    ToursStore.state.currentTours.length > 0 &&
    ToursStore.state.currentTours[0].name === tourName;

  render() {
    const { t } = this.props;

    const btnProps: ButtonProps = {
      htmlType: 'submit',
      loading: this.state.isSaving,
      disabled: !this.state.isValid,
    };

    const cta = this.props.cta ?? t('modals.new_storyboard.button');

    const ToggleAssistant: React.FC = () => {
      if (!this.context.showAi) {
        return null;
      } else {
        return (
          <TourHintable
            hideNext
            overlayPosition="left"
            step={`wizardNewStoryboardToggle`}
          >
            <ToggleButtonWithLabel
              labelPosition="after"
              onChange={() => {
                if (this.context.setUseAssistant) {
                  const state = this.context.useAssistant;

                  if (!state && this.tourIsActive(`wizardNewStoryboard`)) {
                    ToursActions.advanceTour('wizardNewStoryboard');
                  }
                  this.context.setUseAssistant(!state);
                }
              }}
              value={this.context.useAssistant}
              label={t('modals.new_storyboard.useAssistant')}
            />
          </TourHintable>
        );
      }
    };

    return (
      <form className="relative w-full">
        <Dialog
          title={t('modals.new_storyboard.title')}
          isDark
          hideCancel
          hideActions={this.context.useAssistant && this.context.showAi}
          isOpen
          confirmBtnProps={btnProps}
          confirmText={cta}
          onConfirm={this.handleConfirm}
          onCancel={this.props.onClose}
          keepOpen={this.state.isSaving}
          actionChildren={<ToggleAssistant />}
        >
          {!this.context.showAi || !this.context.useAssistant ? (
            <div className="flex flex-col w-full gap-3">
              <div className="space-y-6">
                <TextInput
                  inputSize="lg"
                  label={t('modals.new_storyboard.labels.storyboard_name')}
                  labelClasses="font-semibold text-sm"
                  name="storyboardName"
                  id="storyboardName"
                  autoFocus
                  value={this.state.storyboardName}
                  onChange={this.handleChange}
                  autoComplete="off"
                  required
                  error={this.state.error}
                />

                {(!this.state.starterId || !this.state.starterId.length) && (
                  <NewStoryboardAspectRatio size="lg" />
                )}

                {/* <NewStoryboardTemplates */}
                {/*   value={this.state.starterId} */}
                {/*   onChange={this.handleStarterChange} */}
                {/* /> */}

                {this.props.show_project_dropdown && (
                  <NewStoryboardProjectsList
                    size="md"
                    projectID={this.props.new_storyboard_project.id}
                  />
                )}

                {this.state.isExpanded && !this.state.starterId.length && (
                  <CopyFromStoryboard
                    projectID={this.props.new_storyboard_project.id}
                  />
                )}
              </div>
              {!this.state.starterId.length &&
                this.props.projects &&
                this.props.projects.length > 1 && (
                  <div className="flex justify-end mt-0">
                    <SecondaryLink onClick={this.handleToggleExpand}>
                      {this.state.isExpanded
                        ? t('modals.new_storyboard.hide_options')
                        : t('modals.new_storyboard.more_options')}
                    </SecondaryLink>
                  </div>
                )}
            </div>
          ) : (
            <AiPrompt
              project_id={this.props.new_storyboard_project.id}
              actionChildren={<ToggleAssistant />}
            />
          )}
        </Dialog>
      </form>
    );
  }
}

export default withTranslation()(NewStoryboardForm);
