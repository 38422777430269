/** @prettier */
import * as React from 'react';
import { SVGIconID } from '../../../types/icons';
import Popover from 'blackbird/components/common/popover/Popover';
import Icon from 'blackbird/components/icon/Icon';
import ArrowDown from 'blackbird/images/icons/arrow.svg';
import Button from 'blackbird/components/button/Button';
import { SVGIcon } from 'javascripts/components/shared/SVGIcon';
import { map } from 'underscore';
interface Props {
  value?: SVGIconID;
  onChange: (newIcon: string) => void;
}

// prettier-ignore
const fieldIcons:SVGIconID[] = ['action', 'mute', 'alert','bell','calendar','camera','chat_bubble','clipboard','delete','envelope','eye' ,'gear','globe','headphone','heart','home','location','lock','male', 'female','mic','mobile','monitor','phone','support','wifi'];
export const FrameFieldIconPicker = (props: Props) => {
  const onChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onChange(e.currentTarget.name);
  };
  return (
    <Popover className="flex">
      <Popover.Button className="flex items-center border-r-0 border border-form rounded-sm rounded-r-none">
        {props.value && <SVGIcon icon={props.value} />}
        <Icon icon={<ArrowDown />} />
      </Popover.Button>
      <Popover.Panel className="w-72 bg-white p-5 flex flex-wrap gap-5 shadow-sm rounded-lg">
        {map(fieldIcons, (iconName) => (
          <Button
            type="text"
            className="no-padding"
            key={iconName}
            htmlType="button"
            name={iconName}
            onClick={onChange}
          >
            <SVGIcon icon={iconName} />
          </Button>
        ))}
      </Popover.Panel>
    </Popover>
  );
};
