/** @prettier */

import React, { FC } from 'react';
import { FeedbackKind } from '../types';
import { feedbackBackgrounds, feedbackIcons } from '../constants';
import classNames from 'classnames';
import Icon from '../../icon/Icon';

export interface BannerProps {
  className?: string;
  kind?: FeedbackKind;
  title?: string;
  message?: React.ReactNode;
  hideIcon?: boolean;
  customWidth?: boolean;
}

const Banner: FC<BannerProps> = ({
  className,
  kind = 'info',
  title,
  message,
  hideIcon,
  customWidth,
}) => {
  if (!message) return null;

  return (
    <div className="bg-white">
      <div
        className={classNames(
          'flex flex-nowrap p-3 items-center',
          feedbackBackgrounds[kind],
          className,
          { 'w-96': !customWidth },
        )}
      >
        {!hideIcon && (
          <Icon
            className="h-6 mr-3.5"
            icon={feedbackIcons[kind]}
            color="black"
          />
        )}

        <div className="flex-auto text-sm">
          {Boolean(title) && <div className="flex font-bold">{title}</div>}
          {message}
        </div>
      </div>
    </div>
  );
};

export default Banner;
