/** @format */

import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import Button from '../button/Button';
import { QuizContext } from './QuizContext';
import { QuizProgress } from './QuizProgress';

export const QuizContainer: React.FC = () => {
  const {
    quizSteps,
    currentStep,
    setCurrentStep,
    setSelectedOptions,
    selectedOption,
    setSelectedOption,
  } = useContext(QuizContext);

  useEffect(() => {
    if (currentStep && currentStep.options && currentStep.preselectedOptionId) {
      const preselectedOption = currentStep.options.find(
        (option) => option.id === currentStep.preselectedOptionId,
      );
      setSelectedOption(preselectedOption || null);
    } else {
      setSelectedOption(null);
    }
  }, [currentStep]);

  const handleNext = () => {
    if (currentStep) {
      if (currentStep.options && selectedOption) {
        setSelectedOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          [currentStep.id]: selectedOption.id,
        }));
        if (selectedOption.callback) {
          selectedOption.callback();
        }
        const nextStep = quizSteps.find(
          (step) => step.id === selectedOption.nextStepId,
        );
        setCurrentStep(nextStep);
      } else if (currentStep.nextStepId) {
        const nextStep = quizSteps.find(
          (step) => step.id === currentStep.nextStepId,
        );
        setCurrentStep(nextStep);
      }
    }
  };

  if (!currentStep) {
    return null;
  }

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex flex-col w-full h-full py-8 overflow-y-auto bg-white z-modal">
      <div className="flex justify-center">
        <QuizProgress />
      </div>
      <div className="flex items-center justify-center flex-auto pt-10 pb-10 tall:pb-32 tall:pt-20">
        <div
          className={classNames(
            `w-full `,
            currentStep.containerClassName
              ? currentStep.containerClassName
              : 'max-w-md',
          )}
        >
          <div className="text-xl font-semibold text-center">
            {currentStep.title}
          </div>
          {!currentStep.description ? (
            <div className="mt-8" />
          ) : (
            <div className="mt-4 mb-8 text-center text-type-subdued">
              {currentStep.description}
            </div>
          )}
          {currentStep.component && currentStep.component}
          {currentStep.options ? (
            <div
              className={classNames(
                'grid mt-12',
                currentStep.id === 'step-industry' && 'grid-cols-3 gap-4',
                currentStep.id === 'step-newsletter' && 'grid-cols-2 gap-10',
                currentStep.id === 'step-goals' && 'grid-cols-2 gap-10',
                currentStep.id === 'step-team' && 'grid-cols-2 gap-10',
                currentStep.id === 'step-role' && 'grid-cols-4 gap-6',
              )}
            >
              {currentStep.options.map((option) => (
                <div
                  key={`${currentStep.id}-${option.id}`}
                  onClick={() => setSelectedOption(option)}
                >
                  {React.cloneElement(option.text as React.ReactElement, {
                    selected: selectedOption?.id === option.id,
                  })}
                </div>
              ))}
            </div>
          ) : null}

          {![
            `step-pricing`,
            `step-loading`,
            `step-offer`,
            `step-generate`,
            `step-password`,
          ].includes(currentStep.id) && (
            <div className="flex justify-center mt-8 tall:mt-16">
              <div className="w-full max-w-xs">
                <Button
                  type="solid"
                  rounded
                  size="lg"
                  className="w-full"
                  onClick={handleNext}
                  disabled={currentStep.options && !selectedOption}
                >
                  {currentStep.buttonText || `Continue →`}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

(window as any).QuizContainer = QuizContainer;
