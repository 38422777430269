/** @prettier */
import * as React from 'react';
import * as navigateToRoute from '../../helpers/router/navigate-to-route';
import type { BannerProps } from '../../flux/stores/banner';
import Banner from 'blackbird/components/feedback/banner/Banner';
import Button from 'blackbird/components/button/Button';
import { IconButton } from 'blackbird/components/common/IconButton';
import CloseIcon from 'blackbird/images/icons/close.svg';
import type { FeedbackKind } from 'blackbird/components/feedback/types';
import { Trans, useTranslation } from 'react-i18next';
import {
  preSignupInvitationDismissedLocalState,
  sunsetNotificationLocalState,
  sunsetPendingNotificationLocalState,
  annualUpgradeDismissedLocalState,
  versionNotificationsDismissedLocalState,
} from 'javascripts/helpers/local-state';
import type { StoryboardVersionMonitorData } from 'javascripts/monitors/StoryboardVersionMonitor';
import { OfferFreemiumBannerContainer } from 'blackbird/components/offer/OfferFreemiumBannerContainer';
import { BannerActions } from 'javascripts/flux/actions/banner';
import { SquareMousePointerIcon } from 'lucide-react';
import { BannerWorkflowTrial } from 'blackbird/components/team/trial/BannerWorkflowTrial';
import { BannerPaidTrial } from 'blackbird/components/team/trial/BannerPaidTrial';

interface FrameProps {
  kind?: FeedbackKind;
  flex?: boolean;
  type: string;
  button?: React.ReactElement;
  closable?: boolean | undefined;
  onClose?: () => void;
}

interface IPreSignupInvitation {
  sender_name: string;
  team_name: string;
  invite_token: string;
}

const Frame: React.FunctionComponent<FrameProps> = ({
  children,
  kind,
  button,
  type,
  onClose,
  flex = true,
  closable = false,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: `banners:${type}`,
  });

  return (
    <Banner
      kind={kind}
      hideIcon
      className="font-bold no-padding-y h-banner"
      customWidth
      message={
        <div className="flex items-center justify-between flex-auto">
          <div className={`${flex ? 'flex items-center gap-3' : ''} flex-auto`}>
            {children ? children : t('title')}
            {button && button}
          </div>

          {closable && (
            <IconButton
              aria-label="Close"
              onClick={onClose}
              icon={<CloseIcon />}
            />
          )}
        </div>
      }
    />
  );
};

export const BannerContents = React.memo<BannerProps>((props) => {
  const type = props.type;
  const { t } = useTranslation();

  const FFrame: React.FunctionComponent<FrameProps> = (frameProps) => (
    <Frame
      {...frameProps}
      type={type}
      closable={props.closable ?? frameProps.closable}
      onClose={() => {
        BannerActions.remove(props.type);
        frameProps.onClose?.();
      }}
    />
  );

  if (type === 'billing_alert') {
    return (
      <FFrame
        kind="error"
        type={type}
        button={
          <Button size="sm" htmlType="button" link={t(`banners:${type}.href`)}>
            {t(`banners:${type}.button`)}
          </Button>
        }
      />
    );
  } else if (type === 'offline') {
    return <FFrame kind="error" type={type} closable />;
  } else if (type === 'newVersion') {
    return (
      <Frame
        kind="info"
        type={type}
        closable
        button={
          <Button
            size="sm"
            type="outline"
            htmlType="button"
            onClick={() => window.location.reload()}
          >
            {t(`banners:${type}.button`)}
          </Button>
        }
      />
    );
  } else if (type === 'newStoryboardVersion') {
    const data: StoryboardVersionMonitorData = props.data!;
    return (
      <Frame
        kind="info"
        type={type}
        closable
        button={
          <Button size="sm" type="outline" link={data.url}>
            {t(`banners:${type}.button`)}
          </Button>
        }
        onClose={() => {
          versionNotificationsDismissedLocalState.addToArray(data.storyboardId);
          BannerActions.remove(props.type);
        }}
      >
        {t(`banners:${type}.title`, data)}
      </Frame>
    );
  } else if (type === 'freemium') {
    return <OfferFreemiumBannerContainer />;
  } else if (type === 'confirm_email') {
    return (
      <Frame
        kind="error"
        type={type}
        button={
          <Button
            rounded
            size="sm"
            htmlType="button"
            link={t(`banners:${type}.href`)}
          >
            {t(`banners:${type}.button`)}
          </Button>
        }
      />
    );
  } else if (type === 'v3_trial') {
    return <BannerWorkflowTrial />;
  } else if (type === 'cc_trial') {
    return <BannerPaidTrial />;
  } else if (type === 'annual_upgrade') {
    return (
      <Frame
        kind="info"
        type={type}
        closable
        button={
          <Button
            rounded
            size="sm"
            htmlType="button"
            link={t(`banners:${type}.href`, { slug: BoordsConfig.PlanSlug })}
          >
            {t(`banners:${type}.button`)}
          </Button>
        }
        onClose={() => {
          annualUpgradeDismissedLocalState.setValue(true);
          BannerActions.remove(props.type);
        }}
      />
    );
  } else if (type === 'pre_signup_invitation') {
    const { preSignupInvitation } = BoordsConfig;
    const { sender_name, team_name, invite_token } =
      preSignupInvitation as IPreSignupInvitation;
    return (
      <Frame
        kind="info"
        type={type}
        closable
        button={
          <Button
            size="sm"
            type="outline"
            link={t(`banners:${type}.href`, { invite_token: invite_token })}
          >
            {t(`banners:${type}.button`)}
          </Button>
        }
        onClose={() => {
          preSignupInvitationDismissedLocalState.setValue(true);
          BannerActions.remove(props.type);
        }}
      >
        {t(`banners:${type}.title`, {
          sender_name: sender_name,
          team_name: team_name,
        })}
      </Frame>
    );
  } else if (type === 'sunset_pending') {
    return (
      <Frame
        kind="fancy"
        closable
        type={type}
        onClose={() => {
          sunsetPendingNotificationLocalState.setValue(true);
          BannerActions.remove(props.type);
        }}
        button={
          <Button size="sm" htmlType="button" link={t(`banners:${type}.href`)}>
            {t(`banners:${type}.button`)}
          </Button>
        }
      >
        {t(`banners:${type}.title`, {
          plan: BoordsConfig.Plan,
          date: BoordsConfig.ActiveUntilDate,
        })}
      </Frame>
    );
  } else if (type === 'sunset') {
    return (
      <Frame
        kind="fancy"
        closable
        type={type}
        onClose={() => {
          sunsetNotificationLocalState.setValue(true);
          BannerActions.remove(props.type);
        }}
        button={
          <Button size="sm" htmlType="button" link={t(`banners:${type}.href`)}>
            {t(`banners:${type}.button`)}
          </Button>
        }
      >
        {t(`banners:${type}.title`, {
          plan: BoordsConfig.Plan,
          date: BoordsConfig.SunsetPhaseOneDate,
          days: BoordsConfig.SunsetPhaseOneDaysRemaining,
        })}
      </Frame>
    );
  } else if (type === 'trialing') {
    const trialDaysNumeric = parseInt(BoordsConfig.TrialDaysRemaining, 10);
    return (
      <FFrame
        kind="fancy"
        type={type}
        button={
          <Button
            type="solid"
            size="sm"
            htmlType="button"
            link={t(`banners:${type}.href`)}
          >
            {t(`banners:${type}.button`)}
          </Button>
        }
      >
        <div className="flex items-center space-x-1">
          {trialDaysNumeric <= 3 && (
            <span>{t(`banners:${type}.expires_soon`)}</span>
          )}
          <a
            className="underline hover:no-underline decoration-2 decoration-brand-pink"
            href={t(`banners:${type}.href`)}
          >
            {t(`banners:${type}.days`, {
              days: BoordsConfig.TrialDaysRemaining,
            })}
          </a>
          <span>{t(`banners:${type}.title`)}</span>
        </div>
      </FFrame>
    );
  } else if (type === 'unsupported_extension') {
    return (
      <FFrame kind="error" type={type} flex={false} closable>
        <>
          {t(`banners:${type}.prefix`)}{' '}
          <em>
            <a
              href={props.data.url}
              target="_blank"
              className="underline hover:no-underline"
              rel="noopener noreferrer"
            >
              {props.data.extensionName}
            </a>
          </em>{' '}
          {t(`banners:${type}.suffix`)}
        </>
      </FFrame>
    );
  } else if (type === 'freeloader') {
    return (
      <FFrame
        kind="warning"
        type={type}
        button={
          <Button
            size="sm"
            htmlType="button"
            onClick={() => navigateToRoute('pricing')}
          >
            {t(`banners:${type}.button`)}
          </Button>
        }
      />
    );
  } else if (type === 'unsupported_browser') {
    return (
      <FFrame
        kind="error"
        type={type}
        onClose={() => props.data.handleDismiss()}
        closable
        flex
      >
        <SquareMousePointerIcon className="basis-0 shrink-0" />
        <div>
          <div className="flex-auto p-2">
            <Trans
              t={t}
              i18nKey="banners:unsupported_browser.text"
              // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
              values={{
                browserName: props.data.browserName,
              }}
              // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
              components={{
                NormalWeight: <span className="font-normal" />,
                LearnMore: (
                  <a
                    className="underline hover:no-underline"
                    href={t('banners:unsupported_browser.learnMoreUrl')}
                    onClick={props.data.handleDismiss}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    learn
                  </a>
                ),
                Ignore: (
                  <button
                    type="button"
                    className="underline hover:no-underline"
                    onClick={props.data.handleDismiss}
                  >
                    Ignore
                  </button>
                ),
              }}
            />
          </div>
        </div>
      </FFrame>
    );
  } else {
    return null;
  }
});

BannerContents.displayName = 'BannerContents';
