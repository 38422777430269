/** @prettier */

import React, { useCallback, useState } from 'react';
import type { AvatarSize } from './types';
import classNames from 'classnames';
import logger from 'javascripts/helpers/logger';
import { LoadingIndicator } from '../common/loading-indicator/LoadingIndicator';

/** Sometimes the server returns placeholder urls as data: urls, we want to
 * generate these on the client side */
const filterDataUrl = (string?: string) =>
  string?.indexOf('data:') === 0 ? undefined : string;

export interface AvatarProps {
  className?: string;
  initial?: string;
  image?: string;
  rounded?: boolean;
  size: AvatarSize;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isLoading?: boolean;
  borderColor?: string;
}

const sizes: { [key in AvatarSize]: string } = {
  xxs: 'h-6 w-6',
  xs: 'h-7 w-7',
  sm: 'h-9 w-9',
  lg: 'h-11 w-11',
  xl: 'h-20 w-20',
};

const fonts: { [key in AvatarSize]: string } = {
  xxs: 'text-xs',
  xs: 'text-xs',
  sm: 'leading-4',
  lg: 'text-lg leading-6',
  xl: 'text-4xl',
};

const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>((props, ref) => {
  const {
    className = '',
    initial,
    size,
    rounded = true,
    borderColor = 'border-border-dark',
  } = props;
  // Let's ignore the server's default avatar so we can use our initial ones
  let image = filterDataUrl(props.image);
  if (image && image.indexOf('boords-logo-on-yellow') >= 0) image = undefined;
  const [error, setError] = useState(false);

  const handleError = () => {
    logger.warn('Image not loaded due to network error');
    setError(true);
  };

  const renderContent = useCallback(() => {
    if (image && !error) {
      return (
        <img
          src={image}
          onError={handleError}
          alt=""
          // Tell the browser to expect a square image so the layout doesn't
          // jump around
          height="50"
          width="50"
          className="w-full h-auto"
        />
      );
    }
    return initial || '?';
  }, [image, error, initial]);

  return (
    <div
      ref={ref}
      className={classNames(
        borderColor,
        'inline-flex items-center justify-center overflow-hidden relative select-none',
        rounded ? 'rounded-full' : 'rounded-md',
        sizes[size],
        {
          'font-display box-border ': !image || error,
          'bg-gradient-to-r from-brand-pink-25 to-brand-yellow':
            !image || error,
          [fonts[size]]: !image || error,
          border: (!image || error) && (size === 'xs' || size === 'xxs'),
          'border-2': (!image || error) && size !== 'xs' && size !== 'xxs',
          'cursor-pointer': props.onClick,
        },
        className,
      )}
      onClick={props.onClick}
    >
      {renderContent()}
      <LoadingIndicator show={!!props.isLoading} fill bg />
    </div>
  );
});

Avatar.displayName = 'Avatar';

export default Avatar;
