/** @format */
import { CommentActions } from 'javascripts/flux/actions/comment';
import * as React from 'react';
import CommentEditor from './CommentEditor';
import type {
  CommentEditorProps,
  CommentEditorSubmithandler,
} from './CommentEditor';
import { type EventSource } from 'blackbird/helpers/eventContextHelper';
import { CommentAnnotationsActions } from 'javascripts/flux/actions/commentAnnotations';

// prettier-ignore
interface Props
  extends Pick<
    CommentEditorProps, 'className' | 'placeholder' | 'autoFocus' | 'onClose' | 'inputSize' | 'allowNameField' | 'disabled' | 'userOverride' | 'inputRef'
  > {
  frameId: number;
  storyboardSlug: string;
  context?: EventSource;
}

export const NewCommentForm = React.memo<Props>(
  ({ frameId, context, storyboardSlug, ...editorProps }) => {
    const handleSubmit: CommentEditorSubmithandler = ({
      text,
      name,
      annotation,
      isTeamOnly,
    }) => {
      CommentActions.save.defer({
        frameId: frameId,
        context: context,
        isTeamOnly: isTeamOnly,
        storyboardSlug: storyboardSlug,
        text,
        name,
        annotation,
        callback: (comment) => {
          if (comment.annotation) {
            CommentAnnotationsActions.open({
              commentId: { frameId, commentId: comment.id },
              interactive: false,
              silent: true,
            });
          }
        },
      });
    };

    return (
      <CommentEditor
        {...editorProps}
        onSubmit={handleSubmit}
        commentId={null}
        annotation={null}
        allowAnnotation
      />
    );
  },
);

NewCommentForm.displayName = 'NewCommentForm';
