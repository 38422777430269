/** @format */

import React, { createContext, useState, useCallback } from 'react';
import { apiRequest } from 'blackbird/helpers/apiRequestHelper';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import logger from 'javascripts/helpers/logger';
import { rollbar } from 'javascripts/helpers/rollbar';
import { RequestActions } from 'javascripts/flux/actions/request';

const errorHandler = RequestErrorHandler('Trials');

export type TrialPlan = 'standard' | 'workflow';

interface TrialParams {
  plan: TrialPlan;
  interval: 'month' | 'year';
  currency: 'usd' | 'gbp' | 'eur';
  email: string;
}

interface TrialError {
  errors: {
    status: string;
    source: {
      pointer: string;
    };
    title: string;
  }[];
}

interface TrialResponse {
  data: {
    id: string;
    object: string;
    url: string;
  };
}

export interface CheckoutSession {
  data: {
    customer: string;
    customer_details: {
      name: string;
      email: string;
    };
  };
}

interface EmailValidationResponse {
  data: {
    valid: boolean;
  };
}

interface TrialContextProps {
  createTrial: (
    params: Omit<TrialParams, 'plan'>,
  ) => Promise<TrialResponse | null>;
  fetchCheckoutSession: (id: string) => Promise<CheckoutSession | null>;
  validateEmail: (email: string) => Promise<boolean>;
  isCreatingTrial: boolean;
  isFetchingCheckoutSession: boolean;
  isValidatingEmail: boolean;
  createTrialError: string | null;
  fetchCheckoutSessionError: string | null;
  validateEmailError: React.ReactNode | null;
  currentPlan: 'standard' | 'workflow';
  setCurrentPlan: React.Dispatch<React.SetStateAction<'standard' | 'workflow'>>;
}

export const TrialContext = createContext<TrialContextProps>({
  createTrial: async () => null,
  fetchCheckoutSession: async () => null,
  validateEmail: async () => false,
  isCreatingTrial: false,
  isFetchingCheckoutSession: false,
  isValidatingEmail: false,
  createTrialError: null,
  fetchCheckoutSessionError: null,
  validateEmailError: null,
  currentPlan: 'workflow',
  setCurrentPlan: () => {},
});

export const TrialProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isCreatingTrial, setIsCreatingTrial] = useState(false);
  const [isFetchingCheckoutSession, setIsFetchingCheckoutSession] =
    useState(false);
  const [isValidatingEmail, setIsValidatingEmail] = useState(false);
  const [createTrialError, setCreateTrialError] = useState<string | null>(null);
  const [fetchCheckoutSessionError, setFetchCheckoutSessionError] = useState<
    string | null
  >(null);
  const [validateEmailError, setValidateEmailError] =
    useState<React.ReactNode | null>(null);
  const [currentPlan, setCurrentPlan] = useState<'standard' | 'workflow'>(
    'workflow',
  );

  const createTrial = useCallback(
    async (
      params: Omit<TrialParams, 'plan'>,
    ): Promise<TrialResponse | null> => {
      setIsCreatingTrial(true);
      setCreateTrialError(null);
      try {
        const request = await apiRequest({
          path: 'trials',
          method: 'post',
          payload: { ...params, plan: currentPlan },
        });
        if (!request.ok) {
          const errorResponse: TrialError = await request.json();
          if (errorResponse.errors && errorResponse.errors.length > 0) {
            const errorMessage = errorResponse.errors[0].title;
            throw new Error(errorMessage);
          } else {
            throw new Error('Failed to create trial');
          }
        } else {
          const response: TrialResponse = await request.json();

          Track.event.defer('trial_checkout_start', {
            trialPlan: currentPlan,
            posthogCapture: true,
          });

          window.location.href = response.data.url;
          return response;
        }
      } catch (err) {
        errorHandler({ method: 'post' })(err);
        logger.error(err);
        rollbar.error(err);

        if (err instanceof Error) {
          RequestActions.error.defer(err.message);
        } else {
          RequestActions.error.defer(
            `An unexpected error occurred while creating the trial`,
          );
        }

        return null;
      } finally {
        setIsCreatingTrial(false);
      }
    },
    [currentPlan],
  );

  const fetchCheckoutSession = useCallback(
    async (id: string): Promise<CheckoutSession | null> => {
      setIsFetchingCheckoutSession(true);
      setFetchCheckoutSessionError(null);
      try {
        const request = await apiRequest({
          path: `trials/${id}`,
          method: 'get',
        });
        if (!request.ok) {
          throw new Error('Failed to fetch checkout session');
        }
        const response: CheckoutSession = await request.json();
        return response;
      } catch (err) {
        errorHandler({ method: 'get' })(err);
        logger.error(err);
        rollbar.error(err);
        setFetchCheckoutSessionError(
          'An error occurred while fetching the checkout session',
        );
        return null;
      } finally {
        setIsFetchingCheckoutSession(false);
      }
    },
    [],
  );

  const validateEmail = useCallback(async (email: string): Promise<boolean> => {
    setIsValidatingEmail(true);
    setValidateEmailError(null);
    try {
      const request = await apiRequest({
        path: 'trials/validate',
        method: 'post',
        payload: { email },
      });
      if (!request.ok) {
        const errorResponse: TrialError = await request.json();
        if (request.status === 422) {
          setValidateEmailError(
            <span className="text-sm space-x-1">
              <span className="text-type-subdued">{`Email already registered. Please`}</span>
              <a
                className="text-type-primary hover:underline"
                href="/login"
              >{`sign in.`}</a>
            </span>,
          );
          return false;
        } else if (errorResponse.errors && errorResponse.errors.length > 0) {
          setValidateEmailError(errorResponse.errors[0].title);
          return false;
        } else {
          setValidateEmailError('Failed to validate email');
          return false;
        }
      } else {
        const response: EmailValidationResponse = await request.json();
        return response.data.valid;
      }
    } catch (err) {
      logger.error(err);
      rollbar.error(err);

      setValidateEmailError(
        'An unexpected error occurred while validating the email',
      );
      return false;
    } finally {
      setIsValidatingEmail(false);
    }
  }, []);

  const value: TrialContextProps = {
    createTrial,
    fetchCheckoutSession,
    validateEmail,
    isCreatingTrial,
    isFetchingCheckoutSession,
    isValidatingEmail,
    createTrialError,
    fetchCheckoutSessionError,
    validateEmailError,
    currentPlan,
    setCurrentPlan,
  };

  return (
    <TrialContext.Provider value={value}>{children}</TrialContext.Provider>
  );
};
