/** @prettier */
import * as React from 'react';
import { Listbox } from '@headlessui/react';
import { type GeneratorStyleCategory } from 'javascripts/types/frame';
import { type GeneratorStyleWithPreviewImage } from './GeneratorStyles';
import { GeneratorContext } from './GeneratorContext';
import classNames from 'classnames';
import Tooltip from '../feedback/tooltip/Tooltip';
import { GeneratorCharatcerContext } from './guidelines/GeneratorCharacterContext';
import { hasRolloutFlagEnabled } from 'javascripts/helpers/rollout';
import Badge from '../badge/Badge';

interface GeneratorStyleGroupProps {
  title: string;
  border?: boolean;
  hasCharacters?: boolean;
  category?: GeneratorStyleCategory;
  stylesWithPreviewImages: GeneratorStyleWithPreviewImage[];
}

export const GeneratorStyleGroup: React.FC<GeneratorStyleGroupProps> = ({
  title,
  category,
  hasCharacters,
  border = true,
  stylesWithPreviewImages,
}) => {
  const {
    isFlyoverGrid,
    isPanelbar,
    style: generatorStyle,
  } = React.useContext(GeneratorContext);
  const { style: characterStyle } = React.useContext(GeneratorCharatcerContext);

  const [styles, setStyles] = React.useState<
    GeneratorStyleWithPreviewImage[] | undefined
  >(undefined);

  React.useEffect(() => {
    if (hasCharacters) {
      setStyles(stylesWithPreviewImages.filter((s) => s.hasCharacters));
    } else if (category) {
      setStyles(stylesWithPreviewImages.filter((s) => s.category === category));
    }
  }, [hasCharacters, category, stylesWithPreviewImages]);

  const NewTooltip: React.FC = () => (
    <Tooltip title={<>{`✨ NEW: Introducing Character Guidelines! ✨`}</>}>
      <div className="flex justify-center mt-1">
        <Badge color="blue" label={`New`} size="sm" />
      </div>
    </Tooltip>
  );

  return !styles ? null : (
    <div className={classNames(border && 'border-border border-t pt-4')}>
      <div className="mb-2 text-sm font-semibold">{title}</div>
      <div
        className={classNames(
          'grid mb-4',
          isPanelbar ? 'gap-2 grid-cols-4 ' : 'gap-3 grid-cols-4',
        )}
      >
        {styles.map((s) => (
          <Listbox.Option value={s.value} key={`style-${s.value}`}>
            <div className="relative cursor-pointer group">
              <Tooltip title={s.label} disabled={!isPanelbar}>
                <img
                  src={s.previewUrl}
                  className={classNames(
                    'border-2 rounded-md group-hover:border-brand-pink',
                    (!hasCharacters && s.value === generatorStyle.value) ||
                      (hasCharacters &&
                        characterStyle &&
                        s.value === characterStyle.value)
                      ? 'border-brand-pink'
                      : hasRolloutFlagEnabled(`Characters`) && s.hasCharacters
                      ? 'border-brand-blue'
                      : 'border-white',
                  )}
                />
              </Tooltip>
              {!isPanelbar ? (
                <div className="flex items-center">
                  <div
                    className={classNames(
                      'mt-0.5 ml-0.5 whitespace-nowrap group-hover:text-type-primary flex-auto',
                      isFlyoverGrid ? 'text-sm' : 'text-xs ',
                      (!hasCharacters && s.value === generatorStyle.value) ||
                        (hasCharacters &&
                          characterStyle &&
                          s.value === characterStyle.value)
                        ? 'text-type-primary'
                        : 'text-type-disabled',
                    )}
                  >
                    {s.label}
                  </div>
                  {hasRolloutFlagEnabled(`Characters`) && s.hasCharacters && (
                    <div className="flex-shrink-0">
                      <NewTooltip />
                    </div>
                  )}
                </div>
              ) : (
                hasRolloutFlagEnabled(`Characters`) &&
                s.hasCharacters && <NewTooltip />
              )}
            </div>
          </Listbox.Option>
        ))}
      </div>
    </div>
  );
};
