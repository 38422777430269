/** @prettier */
import { Listbox } from '@headlessui/react';
import { BoordsAiContext } from 'blackbird/helpers/contexts/BoordsAiContext';
import useCustomPopper from 'blackbird/helpers/hooks/useCustomPopper';
import classNames from 'classnames';
import cdnUrl from 'javascripts/helpers/cdn-url.js';
import { generatorStyleLocalState } from 'javascripts/helpers/local-state';
import logger from 'javascripts/helpers/logger';
import { type GeneratorStyle } from 'javascripts/types/frame';
import React, { useContext, useMemo, useState } from 'react';
import { GeneratorContext } from './GeneratorContext';
import { GeneratorStyleGroup } from './GeneratorStyleGroup';
import { GeneratorCharatcerContext } from './guidelines/GeneratorCharacterContext';

export interface GeneratorStyleWithPreviewImage extends GeneratorStyle {
  previewUrl: string;
}

interface GeneratorStylesProps {
  isCharacterStyles?: boolean;
  isNewStoryboardStyles?: boolean;
}

export const GeneratorStyles: React.FC<GeneratorStylesProps> = ({
  isCharacterStyles,
  isNewStoryboardStyles,
}): React.ReactElement => {
  const {
    isFlyoverGrid,
    isPanelbar,
    styles,
    setStyle: setGeneratorStyle,
    style: generatorStyle,
  } = useContext(GeneratorContext);

  const { setStyle: setNewStoryboardStyle } = useContext(BoordsAiContext);

  const {
    isProcessing,
    style: characterStyle,
    setStyle: setCharacterStyle,
  } = useContext(GeneratorCharatcerContext);

  const [parentRef, setParentRef] = useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { attributes, styles: popperStyles } = useCustomPopper(
    parentRef,
    popperElement,
    {
      placement: 'bottom-end',
      distance: 0,
      maxHeight: 430,
    },
  );

  const handleChange = (value: string) => {
    if (isCharacterStyles && styles) {
      setCharacterStyle(styles.find((s) => s.value === value));
    } else if (isNewStoryboardStyles && styles) {
      const newStyle = styles.find((s) => s.value === value);
      if (newStyle) {
        setNewStoryboardStyle(newStyle);
      }
    }
    setGeneratorStyle(styles.find((s) => s.value === value) as GeneratorStyle);
    generatorStyleLocalState.setValue(value);
  };

  const noop = () => {};

  const preloadPreviewUrl = (previewUrl: string) => {
    const i = new Image();
    i.src = previewUrl;

    const handleError = (err: any) =>
      logger.log(`Error preloading image ${i.src}:`, err);

    if (i.decode) {
      i.decode().then(noop).catch(handleError);
    } else {
      i.onload = noop;
      i.onerror = handleError;
    }
  };

  const stylesWithPreviewImages: GeneratorStyleWithPreviewImage[] =
    useMemo(() => {
      return styles.map((s) => {
        const previewUrl = cdnUrl(`/assets/generator-previews/${s.value}.jpg`);
        preloadPreviewUrl(previewUrl);

        return { ...s, previewUrl };
      });
    }, [styles]);

  const SelectedStyle: React.FC = () => {
    const [selectedStyle, setSelectedStyle] = React.useState<
      GeneratorStyle | undefined
    >(undefined);

    React.useEffect(() => {
      if (isCharacterStyles && characterStyle) {
        setSelectedStyle(characterStyle);
      } else {
        setSelectedStyle(generatorStyle);
      }
    }, [isCharacterStyles, characterStyle, generatorStyle]);

    return !selectedStyle ? null : (
      <div
        className={classNames(
          'flex items-center border rounded-sm space-x-2 border-form',
          isPanelbar || isNewStoryboardStyles ? 'px-3 py-2 ' : 'px-4 py-3.5 ',
        )}
      >
        <img
          src={cdnUrl(`/assets/generator-previews/${selectedStyle.value}.jpg`)}
          className="flex-shrink-0 w-6 h-6 rounded-sm"
        />
        <div className={classNames(isNewStoryboardStyles && 'text-sm -mt-1')}>
          {selectedStyle.label}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div
        className={classNames(
          'mb-1 font-semibold',
          isPanelbar || (isNewStoryboardStyles && 'text-sm'),
        )}
      >
        {isNewStoryboardStyles ? `Image Style` : `Style`}
      </div>
      <Listbox value={null} onChange={handleChange} disabled={isProcessing}>
        <Listbox.Button
          ref={(ref) => setParentRef(ref)}
          className={classNames('w-full', isProcessing && 'text-type-disabled')}
        >
          <SelectedStyle />
        </Listbox.Button>

        {stylesWithPreviewImages && (
          <>
            <Listbox.Options
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}
              className={classNames(
                'z-40 overflow-y-auto bg-white rounded-lg shadow-lg focus:outline-none ',

                isFlyoverGrid
                  ? 'px-6 py-6 w-[32.5rem]'
                  : isPanelbar
                  ? 'px-3 py-3 w-[18.5rem]'
                  : 'px-6 py-6 w-[27rem]',
              )}
            >
              {/* Will be used when we enable */}
              {/* styles in the character editor */}
              {isCharacterStyles ? (
                <GeneratorStyleGroup
                  border={false}
                  hasCharacters
                  title={`Character Styles`}
                  stylesWithPreviewImages={stylesWithPreviewImages}
                />
              ) : (
                <>
                  <GeneratorStyleGroup
                    border={false}
                    title={`Popular`}
                    category={`popular`}
                    stylesWithPreviewImages={stylesWithPreviewImages}
                  />

                  <GeneratorStyleGroup
                    title={`Monochrome`}
                    category={`mono`}
                    stylesWithPreviewImages={stylesWithPreviewImages}
                  />

                  <GeneratorStyleGroup
                    title={`Realistic`}
                    category={`realistic`}
                    stylesWithPreviewImages={stylesWithPreviewImages}
                  />

                  <GeneratorStyleGroup
                    title={`Stylized`}
                    category={`stylized`}
                    stylesWithPreviewImages={stylesWithPreviewImages}
                  />
                </>
              )}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
};
