/** @format */
import React, { useState, useEffect } from 'react';
import logger from 'javascripts/helpers/logger';
import Button from '../button/Button';
import TextInput from '../form/text-input/TextInput';
import { CheckIcon } from '@heroicons/react/20/solid';
import { QuizContext } from './QuizContext';
import { apiRequest } from 'blackbird/helpers/apiRequestHelper';
import { RequestActions } from 'javascripts/flux/actions/request';

export const QuizPassword: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { quizSteps, setCurrentStep } = React.useContext(QuizContext);

  const handleProceed = () => {
    setCurrentStep(quizSteps.find((s) => s.id === 'step-role'));
  };

  useEffect(() => {
    const isPasswordValid = password.length >= 8 && confirmPassword.length >= 8;
    setIsButtonDisabled(!isPasswordValid);
    setError(''); // Clear error when user types
  }, [password, confirmPassword]);

  useEffect(() => {
    if (
      BoordsConfig.HasChangedPassword ||
      String(BoordsConfig.CheckoutSignup) !== 'true'
    ) {
      handleProceed();
    }
  }, []);

  const validatePasswords = () => {
    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      return false;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return false;
    }
    return true;
  };

  const createPassword = async () => {
    setIsLoading(true);
    try {
      const response = await apiRequest({
        path: 'password',
        method: 'post',
        payload: {
          password,
          password_confirmation: confirmPassword,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to create password');
      }

      RequestActions.success.defer('Password saved');
      handleProceed();
    } catch (err) {
      logger.error('Error creating password:', err);
      setError('An error occurred while creating the password');
      RequestActions.error.defer(
        'An error occurred while creating the password',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    if (validatePasswords()) {
      createPassword();
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmPassword(e.target.value);
  };

  const renderCheckIcon = () => (
    <span className="pt-1">
      <CheckIcon className="ml-1.5 w-4 h-4 text-type-green" />
    </span>
  );

  return (
    <div>
      <TextInput
        inputSize="md"
        autoFocus
        className="mb-6"
        labelClasses="font-semibold"
        name="password"
        label={
          <span className="inline-flex items-center">
            <span className="font-semibold">Password</span>
            {password.length >= 8 && renderCheckIcon()}
          </span>
        }
        type="password"
        value={password}
        onChange={handlePasswordChange}
      />
      <TextInput
        inputSize="md"
        className="mb-6"
        labelClasses="font-semibold"
        label={
          <span className="inline-flex items-center">
            <span className="font-semibold">Confirm Password</span>
            {confirmPassword !== '' &&
              password === confirmPassword &&
              renderCheckIcon()}
          </span>
        }
        name="confirm_password"
        type="password"
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
      />
      {error && <p className="mb-4 text-red-500">{error}</p>}
      <div className="flex justify-center mt-8 tall:mt-16">
        <Button
          className="w-full max-w-xs"
          rounded
          size="lg"
          type="solid"
          loading={isLoading}
          onClick={handleSubmit}
          disabled={isButtonDisabled || isLoading}
        >
          {`Continue →`}
        </Button>
      </div>
    </div>
  );
};
