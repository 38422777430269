/** @format */
import React, {
  createContext,
  useState,
  useEffect,
  type Dispatch,
  type SetStateAction,
} from 'react';
import i18n from 'i18next';
import {
  type IBillingPlanSlug,
  type LocalPlanData,
} from 'blackbird/helpers/contexts/BillingContext';

interface PricingContextProps {
  billingInterval: string;
  setBillingInterval: Dispatch<SetStateAction<string>>;
  plans: LocalPlanData[] | undefined;
  setPlans: Dispatch<SetStateAction<LocalPlanData[] | undefined>>;
  isPuffinPrices: boolean;
}

export const PricingContext = createContext<PricingContextProps>({
  billingInterval: 'year',
  setBillingInterval: () => {},
  plans: undefined,
  setPlans: () => {},
  isPuffinPrices: false,
});

const initLocalPlans = i18n.t('billing:plans', {
  returnObjects: true,
}) as LocalPlanData[];

interface PricingProviderProps {
  children: React.ReactNode;
}

export const PricingProvider: React.FC<PricingProviderProps> = ({
  children,
}) => {
  const [billingInterval, setBillingInterval] = useState<string>('year');
  const [plans, setPlans] = useState<LocalPlanData[] | undefined>(undefined);
  const isPuffinPrices = BoordsConfig.Rollout.Puffin;

  useEffect(() => {
    const plansToFilter: IBillingPlanSlug[] = isPuffinPrices
      ? ['standard', 'workflow']
      : ['individual', 'group', 'agency', 'powerhouse'];
    const filteredPlans = initLocalPlans.filter((p) =>
      plansToFilter.includes(p.slug),
    );
    if (!isPuffinPrices) {
      filteredPlans.reverse();
    }
    setPlans(filteredPlans);
  }, [isPuffinPrices]);

  useEffect(() => {
    if (BoordsConfig.IsAnnual) {
      setBillingInterval('year');
    } else if (isPuffinPrices) {
      setBillingInterval('month');
    }
  }, [isPuffinPrices]);

  const value = {
    billingInterval,
    setBillingInterval,
    plans,
    setPlans,
    isPuffinPrices,
  };

  return (
    <PricingContext.Provider value={value}>{children}</PricingContext.Provider>
  );
};
