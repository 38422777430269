/** @prettier */
import * as React from 'react';
import cdnUrl from 'javascripts/helpers/cdn-url.js';
import marked from 'marked';
import { StarIcon } from '@heroicons/react/24/solid';

interface Testimonial {
  text: string;
  imgUrl: string;
  name: string;
  designation: string;
}

export const PricingTestimonials: React.FC = (): React.ReactElement => {
  const testimonials: Testimonial[] = [
    {
      name: 'Ryan Walton',
      designation: 'Founder & CEO, Aura Ads',
      text: `"We've literally cut our storyboarding time in half. This is an absolute game-changer for creative output across the agency."`,
      imgUrl: cdnUrl(`/assets/faces/ryan.png`),
    },
    // {
    //   name: 'Lewis Darby',
    //   designation: 'Founder, YardB',
    //   text: 'Before Boords, storyboarding was the **most painful** part of the production process. With Boords it’s the **most painless**.',
    //   imgUrl: cdnUrl(`/assets/faces/lewis.png`),
    // },
    // {
    //   name: 'Charlie Scannell',
    //   designation: 'Producer, Partizan',
    //   text: 'Finding Boords was one of those moments of **pure delight**. I can’t imagine completing storyboards in any other way.',
    //   imgUrl: cdnUrl(`/assets/faces/charlie.png`),
    // },
    // {
    //   name: 'Analia P',
    //   designation: 'Motion Designer',
    //   text: 'Such a **great tool**. It helps us focus on **presenting our ideas** to clients rather than dealing with the back and forth!',
    //   imgUrl: cdnUrl(`/assets/faces/analia.png`),
    // },
    // {
    //   name: 'Hope Morley',
    //   designation: 'COO, Umault',
    //   text: `Boords helps us spend time on the creative, and **saves wasting time** futzing with layouts. It's **every creative's dream.**`,
    //   imgUrl: cdnUrl(`/assets/faces/hope.png`),
    // },
  ];

  const Stars: React.FC = () => (
    <div className="flex items-center mb-1.5 mt-2">
      {[1, 2, 3, 4, 5].map((f) => (
        <StarIcon className="w-5 text-brand-yellow" key={`star-${f}`} />
      ))}
    </div>
  );

  return (
    <div className="grid grid-cols-1 gap-6">
      {testimonials.map((testimonial, index) => {
        const htmlContent = marked(testimonial.text);
        const styledHtml = htmlContent
          .replace(/<strong>/g, `<span class="bg-brand-yellow-25">`)
          .replace(/<\/strong>/g, `</span>`);

        return (
          <div key={index} className={``}>
            <div
              className="text-sm text-left sm:text-base space-x-1 -indent-1"
              dangerouslySetInnerHTML={{ __html: styledHtml }}
            />
            <div className="hidden sm:block">
              <Stars />
            </div>

            <div className="flex items-center mt-2">
              <div className="overflow-hidden leading-none rounded-full">
                <img
                  src={testimonial.imgUrl}
                  alt={testimonial.name}
                  className="w-10 h-10 sm:w-12 sm:h-12"
                />
              </div>
              <div className="ml-2">
                <div className="text-sm text-black">{testimonial.name}</div>
                <div className="text-xs text-type-subdued">
                  {testimonial.designation}
                </div>
              </div>
              <div className="flex justify-end flex-auto flex-shrink-0 sm:hidden">
                <Stars />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
