/** @prettier */

import React, { FC, useEffect, useState } from 'react';
import TextInput from '../text-input/TextInput';
import Panel from '../../panel/Panel';
import Icon from '../../icon/Icon';
import ChevronIcon from '../../../images/icons/arrow.svg';
import ListItem from '../../common/list-item/ListItem';
import type { Option } from '../../common/types';
import Popover from '../../common/popover/Popover';
import classNames from 'classnames';
import { useWidth } from '../../../helpers/hooks/useWidth';
import { useManageIndex } from '../../../helpers/hooks/useManageIndex';
import { useTextSearch } from 'javascripts/helpers/useTextSearch';

export type SelectChangeHandler = (value: string, option: Option) => void;
const searchableProps: (keyof Option)[] = ['label', 'subLabel'];

interface SelectProps {
  size?: React.ComponentProps<typeof TextInput>['inputSize'];
  className?: string;
  disabled?: boolean;
  error?: string;
  label?: string;
  loading?: boolean;
  onChange?: SelectChangeHandler;
  options?: Option[];
  placeholder?: string;
  value?: string;
  selectContainerClassName?: string;
  labelClasses?: string;
  name?: string;
  /**
   * Controls if typing is allowed in the input section of Select
   */
  disableInput?: boolean;
  /** The class name to use for z-indexing the overlay panel
   * FIXME: this isn't that clean of a solution, it should position itself
   * better. Perhaps I can abuse the insideDialogContext for this
   */
  zIndex?: string;
  inputClassName?: string;
}

const getCurrentOption = (options?: Option[], value?: string) =>
  options?.find((o) => o.value === value);

const Select = React.memo<SelectProps>((props) => {
  const {
    className,
    disabled,
    error,
    label,
    loading,
    options = [],
    placeholder,
    value,
    selectContainerClassName,
    size = 'lg',
    name,
    disableInput,
    inputClassName,
    labelClasses,
  } = props;

  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [currentValue, setCurrentValue] = useState<Option | undefined>(
    getCurrentOption(options, value),
  );
  const [placeholderActive, setPlaceholderActive] = useState<boolean>(
    Boolean(currentValue),
  );
  const [controlRef, setControlRef] = useState<HTMLDivElement | null>();
  const [scrollItemInView, setScrollItemInView] = useState(false);
  const enableScrolling = React.useCallback(
    () => setScrollItemInView(true),
    [setScrollItemInView],
  );

  const panelWidth = useWidth(controlRef) + 48;

  /** Show all the values that are not the current selected value  */
  const [filteredOptions, triggerTextSearch] = useTextSearch(
    options,
    searchableProps,
  );

  useEffect(() => {
    if (!open) {
      setText('');
    }
  }, [open]);

  useEffect(() => {
    if (value !== currentValue?.value) {
      setCurrentValue(getCurrentOption(options, value));
    }
  }, [value, options, currentValue]);

  const handleOpen = () => {
    if (open) return;
    if (!disabled) {
      setOpen(true);
      controlRef?.focus();
    }
    setPlaceholderActive(false);
  };

  const handleClose = () => {
    setOpen(false);
    controlRef?.blur();
    setPlaceholderActive(true);
  };

  const handleSelectOption = (option: Option) => {
    setCurrentValue(option);
    props.onChange?.(option.value, option);
    setPlaceholderActive(true);
    handleClose();
  };

  const { currentIndex, setCurrentIndex, onCommit, onCurrentIndexChange } =
    useManageIndex(filteredOptions.length, controlRef);

  /** Register handlers for useManageIndex */
  onCommit(() => handleSelectOption(options![currentIndex]));
  onCurrentIndexChange(enableScrolling);

  const renderOptions = () => {
    if (loading || !options?.length) {
      return null;
    }

    return (
      <>
        {filteredOptions.map((option, index) => (
          <ListItem
            key={option.value ?? option.label}
            option={option}
            onClick={handleSelectOption}
            scrollInView={scrollItemInView}
            highlight={currentIndex === -1 ? undefined : currentIndex === index}
            onMouseOver={() => {
              setScrollItemInView(false);
              setCurrentIndex(index);
            }}
          />
        ))}
      </>
    );
  };

  const renderPanel = (styles) => {
    if (!open) {
      return null;
    }

    const panelStyle = {
      maxHeight: styles.popper.maxHeight,
      width: `${panelWidth}px`,
      fontSize: ['md', 'xs'].includes(size) ? '0.875rem' : '1rem',
    };

    return (
      <Panel
        loading={loading}
        className="left-0 w-full p-2 top-11"
        placeholder={placeholder}
        message={
          text && !filteredOptions?.length
            ? `No results for ${text}`
            : undefined
        }
        style={panelStyle}
      >
        {renderOptions()}
      </Panel>
    );
  };

  const renderChevron = () => (
    <Icon
      color={disabled ? 'disabled' : 'black'}
      icon={<ChevronIcon />}
      className={classNames('w-6 ml-0.5 transition-all', {
        'transform -rotate-180': open,
      })}
      onClick={open ? handleClose : handleOpen}
    />
  );

  return (
    <Popover
      isOpen={open}
      placement="bottom-start"
      portal
      distance={12}
      offset={-14}
    >
      <Popover.Button className={selectContainerClassName}>
        {({ setParentRef }) => (
          <>
            <input type="hidden" value={currentValue?.value} name={name} />
            <TextInput
              readOnly={disableInput}
              inputSize={size}
              /**
               * Keep inactive placeholder color unless a value is selected.
               */
              inputClassName={classNames(
                {
                  'placeholder:text-type-primary': placeholderActive,
                  'cursor-default': disableInput,
                },
                inputClassName,
                size === 'md' && 'text-sm',
              )}
              ref={(ref) => {
                setParentRef && setParentRef(ref);
                setControlRef(ref);
              }}
              className={className}
              disabled={disabled}
              error={error}
              label={label}
              labelClasses={classNames(
                labelClasses,
                ['md', 'lg'].includes(size) && 'font-semibold text-sm !mb-1',
              )}
              onBlur={handleClose}
              placeholder={currentValue?.label || placeholder}
              onClick={handleOpen}
              onChange={(e) => {
                setText(e.currentTarget.value);
                triggerTextSearch(e.currentTarget.value);
                setOpen(true);
                setPlaceholderActive(false);
              }}
              onFocus={handleOpen}
              rightComponent={renderChevron()}
              onKeyDown={(e) => {
                if (e.key === ' ') {
                  e.stopPropagation();
                }
                if (e.key === 'Escape') {
                  handleClose();
                }
              }}
              value={text}
              autoComplete="off"
              // In this case we want to leave the name field to the hidden form
              // field so when it's submitted with a regular HTML form (i.e.
              // CountryAndTaxField the value is correct
            />
          </>
        )}
      </Popover.Button>
      {open && (
        <Popover.Panel className={props.zIndex} static>
          {({ styles }) => renderPanel(styles)}
        </Popover.Panel>
      )}
    </Popover>
  );
});

Select.displayName = 'Select';
export default Select;
