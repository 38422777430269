/** @format */

import React from 'react';
import { ProjectArchive } from './archive/ProjectArchive';
import { ProjectContents } from './ProjectContents';
import { ProjectsContext } from './ProjectsContext';

export const ProjectMainWindow: React.FC = () => {
  const { viewType } = React.useContext(ProjectsContext);

  return viewType === 'archive' ? <ProjectArchive /> : <ProjectContents />;
};
