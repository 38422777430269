/** @prettier */
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import classNames from 'classnames';
import * as React from 'react';

interface FrameButtonContentProps {
  text: string;
  hideText: boolean;
  autoWidth?: boolean;
  customIcon?: boolean;
  IconComponent: React.ComponentType<{ className: string }>;
}

export const FrameButtonContent: React.FC<FrameButtonContentProps> = ({
  text,
  hideText,
  customIcon = false,
  autoWidth = false,
  IconComponent,
}) => {
  const iconClass = classNames(
    `flex-shrink-0`,
    !hideText && 'mr-1.5 -ml-1 ',
    !customIcon && 'w-3.5 h-3.5 ',
    customIcon && '!mr-0',
  );
  return (
    <div
      className={classNames(
        'flex items-center',
        !hideText && !autoWidth && 'w-20',
      )}
    >
      {hideText ? (
        <Tooltip as="div" title={text}>
          <IconComponent className={iconClass} />
        </Tooltip>
      ) : (
        <>
          <IconComponent className={iconClass} />
          <div className="flex-auto -mt-0.5 !text-left">{text}</div>
        </>
      )}
    </div>
  );
};
